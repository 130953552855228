import React, { useState, useMemo, useEffect } from "react";
import { VStack, Box } from "@chakra-ui/react";
import Header from "./Header";
import { useAlerts } from "./Alerts";
import Instructions from "./Instructions";
import Blackjack from "./Blackjack";
import BackgroundGrid from "./components/BackgroundGrid";

function App() {
  const { AlertsComponent, addAlert } = useAlerts();
  const [isBlackjackFullScreen, setIsBlackjackFullScreen] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height:
      typeof window !== "undefined" ? document.documentElement.scrollHeight : 0,
  });

  useEffect(() => {
    const updateDimensions = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: Math.max(
          document.documentElement.scrollHeight,
          window.innerHeight
        ),
      });
    };

    // Set up a ResizeObserver to watch for changes to the body or document size
    const resizeObserver = new ResizeObserver(() => {
      updateDimensions(); // Update dimensions whenever content changes
    });

    resizeObserver.observe(document.documentElement);

    // Handle initial load
    updateDimensions();

    // Cleanup observer on component unmount
    return () => resizeObserver.disconnect();
  }, []);

  const isStandalone = useMemo(() => {
    if (typeof window === "undefined") return false;
    return (
      (window.navigator &&
        "standalone" in window.navigator &&
        window.navigator.standalone) ||
      window.matchMedia("(display-mode: standalone)").matches
    );
  }, []);

  const toggleFullscreen = () => {
    setIsBlackjackFullScreen((prev) => !prev);
  };

  const colors = ["#1E441E", "#2A5A2A", "#3B703B", "#4C8C4C"]; // Four shades of green
  const suitColors = ["#295B29", "#3B703B", "#4C8C4C", "#5DAF5D"]; // Matching suit colors

  return (
    <Box minH="100vh">
      {/* Background grid */}
      <BackgroundGrid
        windowDimensions={windowDimensions} // Dynamically pass full scrollable height
        colors={colors}
        suitColors={suitColors}
      />

      {/* Main App Content */}
      <Box zIndex={2} position="relative">
        {!isBlackjackFullScreen && <Header isStandalone={isStandalone} />}
        <VStack
          spacing={0}
          maxW={isBlackjackFullScreen ? "auto" : "600px"}
          mx="auto"
        >
          {!isBlackjackFullScreen && <Instructions />}
          {!isBlackjackFullScreen && <AlertsComponent />}
          <Blackjack
            addAlert={addAlert}
            fullscreen={isBlackjackFullScreen}
            toggleFullscreen={toggleFullscreen}
            isStandalone={isStandalone}
          />
        </VStack>
      </Box>
    </Box>
  );
}

export default App;
