// components/GameActions.js
import React, { useState, useEffect } from "react";
import { Button, VStack, HStack, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { keyframes } from "@emotion/react";

/**
 * Animations for final state (correct or incorrect).
 */
const pulseGreen = keyframes`
  0% {
    border-color: rgba(0, 255, 0, 0.6);
    color: rgb(255, 255, 255, .9);
  }
  50% {
    border-color: rgb(7, 187, 7);
    color: rgb(255, 255, 255, .9);
  }
  100% {
    border-color: rgba(32, 170, 32, 0.6);
    color: rgb(255, 255, 255, .9);
  }
`;

const pulseRed = keyframes`
  0% {
    border-color: rgba(255, 0, 0, 0.6);
    color: rgb(255, 255, 255, .9);
  }
  50% {
    border-color: rgb(179, 30, 30);
    color: rgb(255, 255, 255, .9);
  }
  100% {
    border-color: rgba(197, 53, 17, 0.6);
    color: rgb(255, 255, 255, .9);
  }
`;

/**
 * Animation for when the button is "busy" (clicked/loading).
 */
const pulseLoading = keyframes`
  0% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.3);
  }
  50% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.6);
  }
  100% {
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.3);
  }
`;

const GameActions = ({
  gameStarted,
  gameSettled,
  showGameActions,
  currentHand,
  canDoubleDown,
  canSplit,
  correctActionHistory,
  lastSnapshot,
  setLastSnapshot,
  hit,
  stand,
  doubleDown,
  split,
  onActionComplete,
  isSplitAce,
}) => {
  const [loadingAction, setLoadingAction] = useState("");
  const [finalState, setFinalState] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const [clickedAction, setClickedAction] = useState("");
  const [userChosenAction, setUserChosenAction] = useState("");

  const [persistedCanSplit, setPersistedCanSplit] = useState(false);
  const [persistedCanDoubleDown, setPersistedCanDoubleDown] = useState(false);

  useEffect(() => {
    if (!finalState && !buttonsDisabled) {
      setPersistedCanSplit(canSplit);
      setPersistedCanDoubleDown(canDoubleDown);
    }
  }, [canSplit, canDoubleDown, finalState, buttonsDisabled]);

  if (isSplitAce) {
    return (
      <VStack spacing={4}>
        <Text color="white" fontWeight="bold">
          Split Aces are automatically settled.
        </Text>
      </VStack>
    );
  }

  const actionButtons = [
    {
      label: "Hit",
      handler: hit,
      visible: true,
    },
    {
      label: "Stand",
      handler: stand,
      visible: true,
    },
    {
      label: "Split",
      handler: split,
      visible: persistedCanSplit,
    },
    {
      label: "Double Down",
      handler: doubleDown,
      visible: persistedCanDoubleDown,
    },
  ];

  const handleActionClick = async (actionLabel, actionHandler) => {
    if (buttonsDisabled) return;

    const snapshotBeforeClick =
      correctActionHistory[correctActionHistory.length - 1] || null;
    setLastSnapshot(snapshotBeforeClick);

    setButtonsDisabled(true);
    setLoadingAction(actionLabel);
    setClickedAction(actionLabel);
    setUserChosenAction(actionLabel);

    try {
      await actionHandler();
    } catch (error) {
      console.error(`Error executing action ${actionLabel}:`, error);
    }

    await onActionComplete?.();

    setFinalState(true);

    const HIGHLIGHT_DURATION = 5000; // ms
    setTimeout(() => {
      if (gameSettled) {
        setButtonsDisabled(true);
      } else {
        setFinalState(false);
        setLoadingAction("");
        setClickedAction("");
        setUserChosenAction("");
        setButtonsDisabled(false);
      }
    }, HIGHLIGHT_DURATION);
  };

  /**
   * Base styles for each button label.
   */
  const labelStyles = {
    Hit: {
      colorScheme: "green",
      bg: "rgba(79, 151, 81, 0.28)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(34, 120, 60, 0.6)",
      },
      transition: "all 0.3s ease",
    },
    Stand: {
      colorScheme: "blue",
      bg: "rgba(38, 85, 130, 0.5)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(38, 85, 130, 0.7)",
      },
      transition: "all 0.3s ease",
    },
    Split: {
      colorScheme: "purple",
      bg: "rgba(128, 60, 128, 0.4)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(128, 60, 128, 0.6)",
      },
      transition: "all 0.3s ease",
    },
    "Double Down": {
      colorScheme: "teal",
      bg: "rgba(38, 100, 100, 0.4)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(38, 100, 100, 0.6)",
      },
      transition: "all 0.3s ease",
    },
  };

  /**
   * Returns custom style based on the button label and state.
   */
  const getButtonStyle = (label) => {
    const def = labelStyles[label] || {};

    const isCorrectAction = lastSnapshot && label === lastSnapshot.action;
    const isUserChoice = label === userChosenAction;

    // Final state: highlight correct or incorrect
    if (finalState) {
      if (isCorrectAction) {
        if (isUserChoice) {
          // Correct and user chose it => green highlight
          return {
            ...def,
            border: "2px solid rgba(0, 255, 0, 0.6)",
            animation: `${pulseGreen} 1.5s ease-in-out infinite`,
          };
        } else {
          // Correct action was something else => red highlight
          return {
            ...def,
            border: "2px solid rgba(255, 0, 0, 0.6)",
            animation: `${pulseRed} 1.5s ease-in-out infinite`,
          };
        }
      }
      // Not the correct action => no special highlight
      return def;
    }

    // Loading/disabled states
    if (buttonsDisabled) {
      if (loadingAction === label) {
        return {
          ...def,
          _hover: {}, // remove hover effect
          cursor: "not-allowed",
          animation: `${pulseLoading} 2.5s ease-in-out infinite`,
        };
      }
      return {
        ...def,
        _hover: {}, // remove hover effect
        opacity: 0.6,
        cursor: "not-allowed",
      };
    }

    // Slight pop if the user just clicked this button
    if (clickedAction === label) {
      return { ...def, transform: "scale(1.05)" };
    }

    // Default style
    return def;
  };

  /**
   * Renders a single button.
   */
  const renderButton = ({ label, handler }) => (
    <Button
      key={label}
      size="lg"
      minW="100px"
      onClick={() => handleActionClick(label, handler)}
      {...getButtonStyle(label)}
      variant={finalState ? "solid" : "outline"}
      position="relative"
      overflow="hidden"
      isDisabled={buttonsDisabled || finalState || gameSettled}
    >
      {label}
    </Button>
  );

  const topRowActions = actionButtons.filter(
    (btn) => ["Hit", "Stand"].includes(btn.label) && btn.visible
  );
  const secondRowActions = actionButtons.filter(
    (btn) => ["Split", "Double Down"].includes(btn.label) && btn.visible
  );

  const renderButtonsLayout = () => {
    if (secondRowActions.length === 0) {
      return (
        <VStack spacing={4}>
          <HStack width="100%" justifyContent="center" spacing={3}>
            {topRowActions.map(renderButton)}
          </HStack>
        </VStack>
      );
    }
    return (
      <VStack spacing={4}>
        <HStack width="100%" justifyContent="center" spacing={3}>
          {topRowActions.map(renderButton)}
        </HStack>
        <HStack width="100%" justifyContent="center" px={4} spacing={3}>
          {secondRowActions.map(renderButton)}
        </HStack>
      </VStack>
    );
  };

  if (!showGameActions || !currentHand.cards?.length || !gameStarted) {
    return null;
  }

  return <VStack>{renderButtonsLayout()}</VStack>;
};

GameActions.propTypes = {
  gameStarted: PropTypes.bool.isRequired,
  gameSettled: PropTypes.bool.isRequired,
  showGameActions: PropTypes.bool.isRequired,
  currentHand: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSplitAce: PropTypes.bool,
  }).isRequired,
  canDoubleDown: PropTypes.bool.isRequired,
  canSplit: PropTypes.bool.isRequired,
  correctActionHistory: PropTypes.array.isRequired,
  lastSnapshot: PropTypes.object,
  setLastSnapshot: PropTypes.func.isRequired,
  hit: PropTypes.func.isRequired,
  stand: PropTypes.func.isRequired,
  doubleDown: PropTypes.func.isRequired,
  split: PropTypes.func.isRequired,
  onActionComplete: PropTypes.func,
  isSplitAce: PropTypes.bool,
};

export default GameActions;
