// Blackjack.js
import React, { useState, useCallback, useRef, useEffect } from "react";
import { VStack, Box, IconButton } from "@chakra-ui/react";
import { ethers, Wallet } from "ethers";
import { useAccount, useChainId, useWalletClient } from "wagmi";
import { FaCompress, FaExpand } from "react-icons/fa";
import Confetti from "react-confetti";

import { testAccounts } from "./testAccounts";
import useColorScheme from "./hooks/useColorScheme";
import useFetchGameData from "./hooks/useFetchGameData";
import useCardAnimation from "./hooks/useCardAnimation";
import { executeContractFunction } from "./utils/ethersUtils";
import calculateHandValues from "./utils/calculateHandValues";
import DealerHand from "./components/DealerHand";
import PlayerHands from "./components/PlayerHands";
import Betting from "./components/Betting";
import PlayerAccount from "./components/PlayerAccount";
import WelcomeScreen from "./components/WelcomeScreen";
import Shuffling from "./components/Shuffling";

const Blackjack = ({
  addAlert,
  fullscreen,
  toggleFullscreen,
  isStandalone,
}) => {
  // **1. Account and Chain Information**
  const { address: connectedAddress, isConnected } = useAccount();
  useWalletClient();
  const chainIdFromHook = useChainId();
  const [blackjackAddress, setBlackjackAddress] = useState(null);
  const [effectiveChainId, setEffectiveChainId] = useState(chainIdFromHook);

  // **2. Promise-based Update Queue**
  const updateQueue = useRef(Promise.resolve());

  // **3. State Variables**
  const [playerAddress, setPlayerAddress] = useState(() => {
    const stored = localStorage.getItem("playerAddress");
    return stored || null;
  });
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [showLabel, setShowLabel] = useState(false);
  const [showGameActions, setShowGameActions] = useState(false);
  const [showBetButton, setShowBetButton] = useState(false);
  const [confettiRunning, setConfettiRunning] = useState(false);
  const [confettiRecycle, setConfettiRecycle] = useState(false);
  const [betAmount, setBetAmount] = useState(
    () => localStorage.getItem("betAmount") || "1"
  );
  const [loadingAction, setLoadingAction] = useState("");
  const [showGameStatus, setShowGameStatus] = useState(false);
  const [showPayoutMessage, setShowPayoutMessage] = useState(false);
  const [betMaxEnabled, setBetMaxEnabled] = useState(false);
  const [showShuffling, setShowShuffling] = useState(false);

  // **4. Fetch Game Data Hook**
  const { gameData, setGameData, fetchGameData, isLoading, setIsLoading } =
    useFetchGameData(playerAddress, provider, addAlert, blackjackAddress);

  const { totalEscrow, dealerBankroll, playerBankroll } = gameData || {};

  // **5. Color Scheme Hook**
  const [colorScheme, setNewColorScheme] = useColorScheme();

  // **6. Card Visibility and Animation States**
  const initializeCardShown = useCallback(
    () => ({
      player: (gameData.playerHands || []).map((hand) =>
        (hand.cards || []).map(() => false)
      ),
      dealer: (gameData.dealerHand || []).map(() => false),
    }),
    [gameData?.playerHands, gameData?.dealerHand]
  );

  const [cardShown, setCardShown] = useState({
    player: [],
    dealer: [],
  });

  const [lastFlippedCardIndex, setLastFlippedCardIndex] = useState({});

  const cardShownRef = useRef(cardShown);
  const lastFlippedCardIndexRef = useRef(lastFlippedCardIndex);
  const previousGameDataRef = useRef(null);

  useEffect(() => {
    cardShownRef.current = cardShown;
    console.log("cardShown updated:", cardShown);
  }, [cardShown]);

  useEffect(() => {
    lastFlippedCardIndexRef.current = lastFlippedCardIndex;
    console.log("lastFlippedCardIndex updated:", lastFlippedCardIndex);
  }, [lastFlippedCardIndex]);

  // **7. Animated Hands and Dealer Cards Reference**
  const [animatedPlayerHands, setAnimatedPlayerHands] = useState([]);
  const [animatedDealerHand, setAnimatedDealerHand] = useState([]);

  // **7a. Animated Dealer Cards Reference**
  const animatedDealerCards = useRef(new Set());

  const { flipHandsSequentially } = useCardAnimation(
    gameData,
    cardShown,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setLastFlippedCardIndex
  );

  // **8. Bet Max Effect**
  useEffect(() => {
    if (betMaxEnabled && gameData?.maxBet) {
      const maxBet = gameData.maxBet.toString();
      setBetAmount(maxBet);
      console.log(`Bet Max Enabled: Setting betAmount to maxBet (${maxBet})`);
    }
  }, [betMaxEnabled, gameData?.maxBet]);

  // **9. Initialize Provider, Signer, and Contract Address**
  useEffect(() => {
    /*
    console.log("Initializing connection...");
    console.log("Current chainId:", chainIdFromHook);
    console.log(
      "Hostname:",
      typeof window !== "undefined" ? window.location.hostname : "SSR"
    );
    */

    const initializeConnection = async () => {
      try {
        let updatedChainId = chainIdFromHook;
        let url = "";
        let newBlackjackAddress = "";

        if (!isConnected) {
          updatedChainId = 31337; // Default to local if not connected
        }

        setEffectiveChainId(updatedChainId);
        console.log(`Effective Chain ID set to: ${updatedChainId}`);

        const isLocal = updatedChainId === 1337 || updatedChainId === 31337;
        const isAmoy = updatedChainId === 80002;
        const isPolygonMainnet = updatedChainId === 137;

        const isProduction =
          typeof window !== "undefined" &&
          window.location.hostname.endsWith("polygonblackjack.com");

        console.log(`Environment isProduction: ${isProduction}`);
        console.log(`Chain ID: ${updatedChainId}`);

        if (isLocal) {
          url = isProduction
            ? "https://hardhat.polygonblackjack.com/"
            : "http://127.0.0.1:8545/";
          newBlackjackAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3";
          console.log(`Local Network - RPC URL: ${url}`);
          console.log(
            `Local Blackjack Contract Address: ${newBlackjackAddress}`
          );
        } else if (isAmoy) {
          url = "https://rpc-amoy.polygon.technology/";
          newBlackjackAddress = "0x1b3dc202F19070deeEa7F25612E8142ABFB47467";
          console.log(`Amoy Network - RPC URL: ${url}`);
          console.log(
            `Amoy Blackjack Contract Address: ${newBlackjackAddress}`
          );
        } else if (isPolygonMainnet) {
          url = "https://polygon-rpc.com/";
          newBlackjackAddress = "YOUR_POLYGON_MAINNET_CONTRACT_ADDRESS"; // **TODO: Update this**
          console.log(`Polygon Mainnet - RPC URL: ${url}`);
          console.log(
            `Polygon Mainnet Blackjack Contract Address: ${newBlackjackAddress}`
          );
        } else {
          console.error(`Unsupported chain ID: ${updatedChainId}`);
          addAlert({
            status: "error",
            title: "Unsupported Network",
            description: `The connected network with Chain ID ${updatedChainId} is not supported.`,
          });
          return;
        }

        // **Validate Contract Address**
        if (
          !ethers.isAddress(newBlackjackAddress) ||
          newBlackjackAddress === "YOUR_POLYGON_MAINNET_CONTRACT_ADDRESS"
        ) {
          console.error(
            "Blackjack contract address is not set or invalid. Please update it."
          );
          addAlert({
            status: "error",
            title: "Configuration Error",
            description:
              "Blackjack contract address is not configured correctly.",
          });
          return;
        }

        console.log("RPC Provider URL set to:", url);
        console.log("Blackjack Contract Address:", newBlackjackAddress);

        setBlackjackAddress(newBlackjackAddress);

        if (isLocal) {
          console.log("Connected to local network");

          let address = playerAddress;
          if (
            !address ||
            !testAccounts.some(
              (acc) => acc.address.toLowerCase() === address.toLowerCase()
            )
          ) {
            const randAccount =
              testAccounts[Math.floor(Math.random() * testAccounts.length)];
            address = randAccount.address;
            setPlayerAddress(address);
            localStorage.setItem("playerAddress", address);
            console.log("Assigned random test account:", address);
          }

          console.log("Player Address:", address);

          const account = testAccounts.find(
            (acc) => acc.address.toLowerCase() === address.toLowerCase()
          );

          console.log("Selected Account:", account);

          if (account && account.privateKey) {
            const walletSigner = new Wallet(
              account.privateKey,
              new ethers.JsonRpcProvider(url)
            );
            setSigner(walletSigner);
            setProvider(walletSigner.provider);
            console.log("Signer set for local account");
          } else {
            const rpcProvider = new ethers.JsonRpcProvider(url);
            setProvider(rpcProvider);
            setSigner(null);
            console.log("No signer available for local account");
          }
        }

        if ((isAmoy || isPolygonMainnet) && isConnected && connectedAddress) {
          console.log(
            `Connected to ${isAmoy ? "Amoy" : "Polygon Mainnet"} network`
          );
          setPlayerAddress(connectedAddress);
          const connectedProvider = new ethers.BrowserProvider(window.ethereum);
          setProvider(connectedProvider);
          const connectedSigner = await connectedProvider.getSigner();
          setSigner(connectedSigner);
          console.log("Signer set for connected wallet");
        }
      } catch (error) {
        console.error("Error in initializeConnection:", error);
        addAlert({
          status: "error",
          title: "Connection Initialization Failed",
          description: "An error occurred while initializing the connection.",
        });
      }
    };

    initializeConnection();
  }, [chainIdFromHook, isConnected, connectedAddress, playerAddress, addAlert]);

  // **10. Fetch Game Data Effect**
  useEffect(() => {
    if (playerAddress && provider && blackjackAddress) {
      console.log("Fetching game data...");
      fetchGameData();
    }
  }, [playerAddress, provider, blackjackAddress, fetchGameData]);

  // **11. Card Animation and Visibility Handlers**

  // Function to add a placeholder card to a player's hand
  const addPlaceholderCardToHand = useCallback((handIndex) => {
    setAnimatedPlayerHands((prev) => {
      const updated = [...prev];
      updated[handIndex] = [...(updated[handIndex] || []), "??"];
      return updated;
    });
    setCardShown((prev) => {
      const updated = { ...prev };
      updated.player = [...(prev.player || [])];
      // Ensure the hand exists
      if (!updated.player[handIndex]) {
        updated.player[handIndex] = [];
      }
      updated.player[handIndex] = [...(prev.player[handIndex] || []), false];
      return updated;
    });
    setLastFlippedCardIndex((prev) => ({
      ...prev,
      [handIndex]: (prev[handIndex] || 0) + 1,
    }));
    console.log(`Added placeholder card to hand index ${handIndex}`);
  }, []);

  const resetUIStates = useCallback(() => {
    setShowGameStatus(false);
    setShowPayoutMessage(false);
    setShowLabel(false);
    setShowBetButton(false);
    console.log(
      "Resetting showGameStatus, showPayoutMessage, showLabel, showBetButton"
    );
  }, []);

  const resetAllShowStates = useCallback(() => {
    resetUIStates(); // Reset other UI states
    setShowGameActions(false); // Additionally hide action buttons
    console.log("Resetting all show states including showGameActions");
  }, [resetUIStates]);

  // **13. Clear the game board**
  const clearBoard = useCallback(() => {
    console.log(`Clearing the game ${blackjackAddress}`);
    setConfettiRecycle(false);
    const initialPlayerHands = [{ cards: [] }]; // Corrected structure
    const initialDealerHand = [];
    setGameData({
      playerHands: initialPlayerHands,
      dealerHand: initialDealerHand,
      gameStarted: false,
      gameSettled: false,
      currentHandIndex: 0,
      betAmounts: [],
      handStatuses: [],
      payouts: [],
      handReasons: [],
    });
    setAnimatedPlayerHands(initialPlayerHands.map(() => [])); // Initialize as array of arrays
    setAnimatedDealerHand(initialDealerHand);
    const initializedCardShown = {
      player: initialPlayerHands.map((hand) => hand.cards.map(() => false)),
      dealer: [false, false], // Both dealer cards are face-down
    };
    setCardShown(initializedCardShown);
    cardShownRef.current = initializedCardShown;
    setLastFlippedCardIndex({});
    resetAllShowStates();

    previousGameDataRef.current = null;

    setNewColorScheme();
    console.log("Cleared the game board.");

    // **Reset animatedDealerCards**
    if (animatedDealerCards.current) {
      animatedDealerCards.current.clear();
      console.log("Reset animatedDealerCards.");
    }
  }, [
    blackjackAddress,
    setGameData,
    setAnimatedPlayerHands,
    setAnimatedDealerHand,
    setCardShown,
    setNewColorScheme,
    resetAllShowStates,
    animatedDealerCards,
  ]);

  // **12. Centralize Action Buttons Display Control**
  const showActionsWithDelay = useCallback(async () => {
    const ANIMATION_SETTLE_TIME = 1000;
    await new Promise((resolve) => setTimeout(resolve, ANIMATION_SETTLE_TIME));
    setShowGameActions(true);
    console.log("Displayed game actions after delay.");
  }, []);

  // Utility to check if a card is already in the desired state (face-up vs face-down)
  const isCardInDesiredState = useCallback(
    (handType, handIndex, cardIndex, faceDown) => {
      const desiredState = !faceDown;

      const cardValue =
        handType === "player"
          ? cardShownRef.current.player?.[handIndex]?.[cardIndex]
          : cardShownRef.current.dealer?.[cardIndex];

      // If we expect faceDown but the card is "??", or vice versa, short-circuit
      if (faceDown && cardValue !== "??") {
        return false;
      }

      if (!faceDown && cardValue === "??") {
        return false;
      }

      if (handType === "player") {
        return (
          cardShownRef.current.player &&
          cardShownRef.current.player[handIndex] &&
          cardShownRef.current.player[handIndex][cardIndex] === desiredState
        );
      } else if (handType === "dealer") {
        return (
          cardShownRef.current.dealer &&
          cardShownRef.current.dealer[cardIndex] === desiredState
        );
      }
      return false;
    },
    []
  );

  // Animate flipping and revealing a card
  const animateAndRevealCard = useCallback(
    async (handType, handIndex, cardIndex, cardValue, faceDown = false) => {
      // Skip if the card is already in the desired state
      if (isCardInDesiredState(handType, handIndex, cardIndex, faceDown)) {
        console.log(
          `Card ${handType} ${
            handIndex !== null ? handIndex : ""
          } ${cardIndex} is already in desired state, skipping.`
        );
        return;
      }

      console.log(
        `Animating and revealing card: ${handType}, Hand: ${handIndex}, Card: ${cardIndex}, Value: ${cardValue}, FaceDown: ${faceDown}`
      );

      // Step 1: Show placeholder "??"
      if (handType === "player") {
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          if (!updated[handIndex]) updated[handIndex] = [];
          updated[handIndex][cardIndex] = "??";
          return updated;
        });
      } else if (handType === "dealer") {
        setAnimatedDealerHand((prev) => {
          const updated = [...prev];
          updated[cardIndex] = "??";
          return updated;
        });
      }

      await new Promise((resolve) => setTimeout(resolve, 350));

      // Step 2: Reveal actual card if faceDown = false
      if (!faceDown) {
        if (handType === "player") {
          setAnimatedPlayerHands((prev) => {
            const updated = [...prev];
            updated[handIndex][cardIndex] = cardValue;
            return updated;
          });
        } else if (handType === "dealer") {
          setAnimatedDealerHand((prev) => {
            const updated = [...prev];
            updated[cardIndex] = cardValue;
            return updated;
          });
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 350));

      // Step 3: Update face-up (true) or face-down (false)
      setCardShown((prev) => {
        const updated = { ...prev };
        if (handType === "player") {
          if (!updated.player) updated.player = [];
          if (!updated.player[handIndex]) updated.player[handIndex] = [];
          updated.player[handIndex][cardIndex] = !faceDown;
        } else if (handType === "dealer") {
          if (!Array.isArray(updated.dealer)) {
            updated.dealer = [];
          }
          // Ensure the dealer array has enough elements
          while (updated.dealer.length <= cardIndex) {
            updated.dealer.push(false);
          }
          updated.dealer[cardIndex] = !faceDown;
        }
        return updated;
      });

      await new Promise((resolve) => setTimeout(resolve, 350));
      console.log(`Completed animating card: ${handType} ${cardIndex}`);
    },
    [isCardInDesiredState]
  );

  // **13. Player Hand Updates**
  const handlePlayerHandUpdates = useCallback(async () => {
    const currentPlayerHands = gameData.playerHands || [];
    const previousPlayerHands = previousGameDataRef.current?.playerHands || [];

    for (
      let handIndex = 0;
      handIndex < currentPlayerHands.length;
      handIndex++
    ) {
      const currentHand = currentPlayerHands[handIndex]?.cards || [];
      const previousHand = previousPlayerHands[handIndex]?.cards || [];

      for (let cardIndex = 0; cardIndex < currentHand.length; cardIndex++) {
        const currentCard = currentHand[cardIndex];
        const previousCard = previousHand[cardIndex];

        if (currentCard && currentCard !== previousCard) {
          if (!isCardInDesiredState("player", handIndex, cardIndex, false)) {
            await animateAndRevealCard(
              "player",
              handIndex,
              cardIndex,
              currentCard
            );
          }
        }
      }
    }
    console.log("Handled player hand updates.");
  }, [gameData.playerHands, animateAndRevealCard, isCardInDesiredState]);

  // **14. Split Detection**
  const detectSplit = useCallback(() => {
    const previousHandCount =
      previousGameDataRef.current?.playerHands?.length || 1;
    const currentHandCount = gameData.playerHands?.length || 1;
    const splitOccurred = currentHandCount > previousHandCount;
    console.log(
      `Split detection: previousHandCount=${previousHandCount}, currentHandCount=${currentHandCount}, splitOccurred=${splitOccurred}`
    );
    return splitOccurred;
  }, [gameData.playerHands]);

  // **15. Handle Split**
  const handleSplit = useCallback(async () => {
    const previousHandCount =
      previousGameDataRef.current?.playerHands?.length || 1;
    const newHands = gameData.playerHands.slice(previousHandCount);

    console.log(`Handling split for ${newHands.length} new hand(s).`);

    for (let i = 0; i < newHands.length; i++) {
      const handIndex = previousHandCount + i;
      const newHand = newHands[i];
      const firstCard = newHand.cards?.[0];
      const secondCard = newHand.cards?.[1];
      const isSplitAce = newHand.isSplitAce; // Assuming gameData includes isSplitAce

      if (!firstCard || !secondCard) {
        console.error("Cannot split, insufficient cards in new hand.");
        addAlert({
          status: "error",
          title: "Split Failed",
          description: "Cannot split, insufficient cards in the new hand.",
        });
        continue;
      }

      // Pre-fill "??" for second card
      setAnimatedPlayerHands((prev) => {
        const updated = [...prev];
        updated[handIndex] = [firstCard, "??"];
        return updated;
      });

      setCardShown((prev) => ({
        ...prev,
        player: [...(prev.player || []), [true, false]],
      }));

      // Animate reveal of second card
      await animateAndRevealCard("player", handIndex, 1, secondCard, false);

      // If it's a split ace, indicate automatic settlement
      if (isSplitAce) {
        console.log(`Hand ${handIndex} is a split ace.`);
        // Optionally, add visual indicators or messages
        // This can be handled in the PlayerHands component
      }
    }

    console.log(`Handled split action for ${newHands.length} new hand(s).`);
  }, [gameData.playerHands, animateAndRevealCard, addAlert]);

  // **16. Main Game Update Function with Promise Queue**
  const handleGameUpdate = useCallback(async () => {
    if (!gameData) return;

    updateQueue.current = updateQueue.current.finally(async () => {
      try {
        const previousGameData = previousGameDataRef.current;

        // A) If the game is settled, handle end of game
        if (gameData.gameSettled) {
          console.log("Game is settled. Handling end of game.");
          await handlePlayerHandUpdates();

          const dealerHand = gameData.dealerHand || [];
          for (let i = 0; i < dealerHand.length; i++) {
            const cardValue = dealerHand[i];
            await animateAndRevealCard("dealer", null, i, cardValue, false);
            animatedDealerCards.current.add(`${i}-${cardValue}`);
          }

          flipHandsSequentially("dealer");
          setShowGameStatus(true);
          setShowPayoutMessage(true);

          setShowGameActions(false);
          setShowBetButton(true);
          console.log("Game settled. Showing game status and payout message.");
        }
        // B) If the game just started, handle initial deal
        else if (
          gameData.gameStarted &&
          (!previousGameData || !previousGameData.gameStarted)
        ) {
          console.log("Game has just started. Handling initial deal.");
          // Reset animations
          setAnimatedPlayerHands([]);
          setAnimatedDealerHand([]);
          setCardShown(initializeCardShown());

          // **Reset animatedDealerCards**
          animatedDealerCards.current.clear();

          // Initial deal sequence: player1, dealer1, player2, dealer2 (face down)
          const initialDealOrder = [
            {
              handType: "player",
              handIndex: 0,
              cardIndex: 0,
              faceDown: false,
            },
            {
              handType: "dealer",
              handIndex: null,
              cardIndex: 0,
              faceDown: false,
            },
            {
              handType: "player",
              handIndex: 0,
              cardIndex: 1,
              faceDown: false,
            },
            {
              handType: "dealer",
              handIndex: null,
              cardIndex: 1,
              faceDown: true, // second dealer card face-down
            },
          ];

          for (const deal of initialDealOrder) {
            const { handType, handIndex, cardIndex, faceDown } = deal;
            const cardValue =
              handType === "player"
                ? gameData.playerHands?.[handIndex]?.cards?.[cardIndex]
                : gameData.dealerHand?.[cardIndex];

            if (cardValue) {
              await animateAndRevealCard(
                handType,
                handIndex,
                cardIndex,
                cardValue,
                faceDown
              );
            }
          }

          // Handle any additional cards beyond the initial deal
          const playerHands = gameData.playerHands || [];
          for (let handIndex = 0; handIndex < playerHands.length; handIndex++) {
            const playerHand = playerHands[handIndex] || {};

            // For the first hand, we already dealt 2 cards
            const startCardIndex = handIndex === 0 ? 2 : 0;

            for (
              let cardIndex = startCardIndex;
              cardIndex < (playerHand.cards?.length || 0);
              cardIndex++
            ) {
              const cardValue = playerHand.cards[cardIndex];
              if (cardValue) {
                await animateAndRevealCard(
                  "player",
                  handIndex,
                  cardIndex,
                  cardValue,
                  false
                );
              }
            }
          }

          console.log("Initial deal complete. Allowing player actions.");

          // **Replace direct setting with delayed action**
          await showActionsWithDelay();

          setShowBetButton(false);
        }
        // C) If a split just happened, handle split
        else if (detectSplit()) {
          console.log("Split detected. Handling split.");
          await handleSplit();

          await showActionsWithDelay();
        }
        // D) Handle incremental updates (hit, stand, etc.)
        else if (previousGameData) {
          console.log("Handling incremental game data updates.");

          // Player updates (new cards, etc.)
          await handlePlayerHandUpdates();

          // Dealer updates
          const currentDealerHand = gameData.dealerHand || [];
          const previousDealerHand =
            previousGameDataRef.current.dealerHand || [];

          for (let i = 0; i < currentDealerHand.length; i++) {
            const currentCard = currentDealerHand[i];
            const previousCard = previousDealerHand[i];

            if (
              currentCard &&
              currentCard !== previousCard &&
              !animatedDealerCards.current.has(`${i}-${currentCard}`)
            ) {
              await animateAndRevealCard("dealer", null, i, currentCard, false);
              animatedDealerCards.current.add(`${i}-${currentCard}`);
            }
          }

          // If game still in progress, show action buttons after animations
          if (gameData.gameStarted && !gameData.gameSettled) {
            await showActionsWithDelay();
            setShowBetButton(false);
          }
        }

        // Record the new state as "previous"
        previousGameDataRef.current = JSON.parse(JSON.stringify(gameData));
        console.log("Updated previousGameDataRef.");
      } catch (error) {
        console.error("Error during game update:", error);
        addAlert({
          status: "error",
          title: "Game Update Error",
          description: "An error occurred while updating the game state.",
        });
      }
    });
  }, [
    gameData,
    handlePlayerHandUpdates,
    animateAndRevealCard,
    flipHandsSequentially,
    handleSplit,
    detectSplit,
    addAlert,
    initializeCardShown,
    showActionsWithDelay,
    animatedDealerCards,
  ]);

  // **17. Effect to Enqueue Game Updates**
  useEffect(() => {
    handleGameUpdate();
  }, [handleGameUpdate]);

  // **Effect to Hide Shuffling Once Game Starts**
  useEffect(() => {
    if (gameData?.gameStarted) {
      setShowShuffling(false);
      console.log("Game has started. Hiding shuffling.");
    }
  }, [gameData?.gameStarted]);

  // **18. Confetti Effect for Blackjack**
  useEffect(() => {
    if (gameData?.handStatuses?.includes("BLACKJACK!") && !confettiRecycle) {
      setConfettiRunning(true);
      setConfettiRecycle(true);
      console.log("Triggering confetti for Blackjack!");
    }
  }, [gameData?.handStatuses, confettiRecycle]);

  // **19. Start New Game Function**
  const startNewGame = useCallback(async () => {
    if (!signer) {
      addAlert({
        status: "error",
        title: "Signer not available",
        description: "Ensure you are connected correctly.",
      });
      return;
    }
    if (!playerAddress) {
      addAlert({
        status: "error",
        title: "Player address not available",
        description: "Ensure you are connected correctly.",
      });
      return;
    }

    const numericBetAmount = parseFloat(betAmount);
    const numericMaxBet = parseFloat(gameData?.maxBet || "0");

    console.log(
      `Attempting to start new game with betAmount: ${numericBetAmount} and maxBet: ${numericMaxBet}`
    );

    if (isNaN(numericBetAmount) || numericBetAmount <= 0) {
      addAlert({
        status: "error",
        title: "Invalid Bet",
        description: "Please enter a valid bet amount greater than 0.",
      });
      return;
    }

    if (isNaN(numericMaxBet) || numericMaxBet <= 0) {
      addAlert({
        status: "error",
        title: "Maximum Bet Not Set",
        description: "The game is currently not accepting bets.",
      });
      return;
    }

    if (numericBetAmount > numericMaxBet) {
      addAlert({
        status: "error",
        title: "Bet Exceeds Maximum",
        description: `The maximum allowed bet is ${numericMaxBet}.`,
      });
      return;
    }

    setIsLoading(true);
    setLoadingAction("Placing Bet");

    localStorage.setItem("betAmount", numericBetAmount.toString());
    clearBoard();
    setShowShuffling(true);

    try {
      await executeContractFunction({
        signer,
        blackjackAddress,
        setIsLoading,
        fetchGameData,
        addAlert,
        functionName: "startGame",
        functionArgs: [],
        value: ethers.parseEther(numericBetAmount.toString()),
        setLoadingAction,
        maxRetries: 5,
      });
      console.log("startGame function executed successfully.");
    } catch (error) {
      console.error("Error starting new game:", error);
      addAlert({
        status: "error",
        title: "Transaction Failed",
        description:
          error.message || "An error occurred while starting a new game.",
      });
      setShowShuffling(false);
    } finally {
      setLoadingAction("");
      setIsLoading(false);
    }
  }, [
    fetchGameData,
    gameData?.maxBet,
    signer,
    playerAddress,
    betAmount,
    addAlert,
    clearBoard,
    blackjackAddress,
    setIsLoading,
  ]);

  // **20. Bet Amount Change Handler**
  const handleBetAmountChange = useCallback(
    (value) => {
      // Only allow valid numeric inputs
      if (
        value === "" ||
        value === "." ||
        /^(\d+(\.\d*)?|\.\d*)$/.test(value)
      ) {
        setBetAmount(value);
        if (betMaxEnabled) {
          setBetMaxEnabled(false);
          console.log("Bet Max disabled due to user input.");
        }
      }
    },
    [betMaxEnabled]
  );

  // **21. Generalized Player Action Handler**
  const handleAction = useCallback(
    (actionFunction) => async (handIndex) => {
      console.log(
        `Action triggered: ${actionFunction} on hand index ${handIndex}`
      );

      if (!signer || !playerAddress) {
        addAlert({
          status: "error",
          title: "Signer Not Available",
          description: "Signer is not available, try reconnecting.",
        });
        return;
      }

      resetUIStates(); // Use resetUIStates instead of resetShowStates
      const actionMap = {
        hit: "Hitting",
        stand: "Standing",
        doubleDown: "Doubling Down",
        split: "Splitting",
      };
      setLoadingAction(actionMap[actionFunction] || "Loading");
      console.log(`Handling action: ${actionFunction}`);

      // Only add placeholder if it's a hit or doubleDown
      if (actionFunction === "hit" || actionFunction === "doubleDown") {
        addPlaceholderCardToHand(handIndex);
      }

      // Remove the local state updates for 'split'
      if (actionFunction === "split") {
        const currentHand = gameData.playerHands?.[handIndex] || {};
        const firstCard = currentHand.cards?.[0];
        const secondCard = currentHand.cards?.[1];
        const isSplitAce = currentHand.isSplitAce;

        if (!firstCard || !secondCard) {
          console.error("Cannot split, insufficient cards.");
          addAlert({
            status: "error",
            title: "Split Failed",
            description: "Cannot split, insufficient cards in hand.",
          });
          setLoadingAction("");
          return;
        }

        console.log("Performing split action.");

        setGameData((prev) => {
          const updated = { ...prev };
          updated.playerHands = [...(prev.playerHands || [])];
          updated.playerHands[handIndex] = {
            ...updated.playerHands[handIndex],
            cards: [firstCard, "??"],
            isSplitAce: isSplitAce,
          };
          updated.playerHands.splice(handIndex + 1, 0, {
            ...updated.playerHands[handIndex],
            cards: [secondCard, "??"],
            isSplitAce: isSplitAce,
          });
          return updated;
        });
        setAnimatedPlayerHands((prev) => {
          const updated = [...prev];
          updated[handIndex] = [firstCard, "??"];
          updated.splice(handIndex + 1, 0, [secondCard, "??"]);
          return updated;
        });
        setCardShown((prev) => {
          const updatedPlayer = [...(prev.player || [])];
          if (updatedPlayer[handIndex]) {
            updatedPlayer[handIndex][1] = false; // Set second card to face-down
          }
          updatedPlayer.push([true, false]); // Add new hand's cardShown
          return {
            ...prev,
            player: updatedPlayer,
          };
        });
        setLastFlippedCardIndex((prev) => ({
          ...prev,
          [handIndex]: 0,
          [handIndex + 1]: 0,
        }));
        console.log("Performed split action.");
      }

      let value = null;
      if (actionFunction === "doubleDown" || actionFunction === "split") {
        const currentBet =
          gameData.betAmounts?.[gameData.currentHandIndex] || "0";
        value = ethers.parseEther(parseFloat(currentBet).toString());
        console.log(
          `Action ${actionFunction} requires value: ${value.toString()}`
        );
      }

      try {
        await executeContractFunction({
          signer,
          blackjackAddress,
          setIsLoading,
          fetchGameData,
          addAlert,
          functionName: actionFunction,
          functionArgs: [],
          value,
          setLoadingAction,
          maxRetries: 5,
        });
        console.log(`Action ${actionFunction} executed successfully.`);
      } catch (error) {
        console.error(`Error executing action ${actionFunction}:`, error);
        addAlert({
          status: "error",
          title: "Transaction Failed",
          description:
            error.message ||
            `An error occurred while performing ${actionFunction}.`,
        });
      }

      await fetchGameData();

      // Flip newly-dealt player cards, if any
      if (["hit", "doubleDown", "split"].includes(actionFunction)) {
        setCardShown((prev) => ({
          ...prev,
          player: (gameData.playerHands || []).map((hand, hIdx) =>
            (hand.cards || []).map((card, cIdx) =>
              prev.player[hIdx] && prev.player[hIdx][cIdx] !== undefined
                ? prev.player[hIdx][cIdx]
                : false
            )
          ),
        }));
        flipHandsSequentially("player");
        console.log(`Completed action ${actionFunction}.`);
      }

      setLoadingAction("");
    },
    [
      signer,
      playerAddress,
      addAlert,
      resetUIStates,
      addPlaceholderCardToHand,
      fetchGameData,
      flipHandsSequentially,
      gameData.currentHandIndex,
      gameData.playerHands,
      gameData.betAmounts,
      blackjackAddress,
      setGameData,
      setIsLoading,
    ]
  );

  // **22. Calculate Current Hand Details**
  const currentHand = gameData?.playerHands?.[gameData.currentHandIndex] || {};
  const possibleHandValues = calculateHandValues(currentHand.cards || []);
  const getCardValueLocal = (card) =>
    card ? (card.length === 3 ? card.slice(0, 2) : card.slice(0, 1)) : "0";
  const canDoubleDown =
    currentHand.cards?.length === 2 &&
    possibleHandValues.some((val) => [9, 10, 11].includes(val)) &&
    !gameData.gameSettled &&
    !currentHand.isSplitAce; // Prevent double down on split aces
  const canSplit =
    currentHand.cards?.length === 2 &&
    getCardValueLocal(currentHand.cards[0]) ===
      getCardValueLocal(currentHand.cards[1]) &&
    !gameData.gameSettled &&
    !currentHand.isSplitAce; // Prevent split on split aces

  // **23. Handle Account Change**
  const handleAccountChange = useCallback(
    (newAddress) => {
      console.log("Account changed to:", newAddress);
      clearBoard();
      setPlayerAddress(newAddress);
      localStorage.setItem("playerAddress", newAddress);
    },
    [clearBoard]
  );

  return (
    <Box w="100%" h="100%" px={fullscreen ? 0 : 3}>
      <Box
        w="100%"
        minH={fullscreen ? "100dvh" : "auto"}
        bg="radial-gradient(circle, #35654d 0%, #2f5a46 40%, #254c3a 100%)"
        borderColor="#0E3E26"
        borderRadius={fullscreen ? "0" : "13px"}
        borderWidth={fullscreen ? 0 : 4}
        py={fullscreen ? 5 : 0}
        position="relative"
        align="center"
        justify="center"
        boxShadow="inset 0 0 15px rgba(0, 0, 0, 0.3), inset 0 0 3px rgba(109, 134, 112, 0.36)"
        alignContent="center"
        overflow="visible"
        mb={fullscreen ? 0 : 3}
      >
        <IconButton
          icon={fullscreen ? <FaCompress /> : <FaExpand />}
          onClick={toggleFullscreen}
          aria-label="Toggle Fullscreen"
          colorScheme="whiteAlpha"
          size="lg"
          position="absolute"
          right={0}
          top={isStandalone && fullscreen ? 8 : 0}
          _hover={{ transform: "scale(1.05)" }}
          cursor="pointer"
          zIndex={1500}
          variant="ghost"
        />
        {confettiRunning && (
          <Confetti
            run={confettiRunning}
            recycle={confettiRecycle}
            tweenDuration={10000}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 1000,
            }}
          />
        )}
        <Box overflowX="auto" w="100%">
          <Box w="100%">
            <VStack w="100%">
              {showShuffling ? (
                <Box h="540px" w="100%">
                  <Shuffling colorScheme={colorScheme} />
                </Box>
              ) : !gameData?.gameStarted && !isLoading ? (
                <Box h="540px">
                  <WelcomeScreen
                    ethAmount={dealerBankroll}
                    escrowAmount={totalEscrow}
                  />
                </Box>
              ) : (
                <Box h="540px">
                  <Box h="208px">
                    <DealerHand
                      animatedDealerHand={animatedDealerHand}
                      colorScheme={colorScheme}
                      gameStarted={gameData.gameStarted}
                      ethAmount={dealerBankroll}
                      escrowAmount={totalEscrow}
                      gameLoaded={gameData.gameLoaded}
                      handIndex={0}
                    />
                  </Box>
                  <Box>
                    <PlayerHands
                      animatedPlayerHands={animatedPlayerHands}
                      colorScheme={colorScheme}
                      gameData={gameData}
                      showLabel={showLabel}
                      showGameActions={showGameActions}
                      isLoading={isLoading}
                      handleAction={handleAction}
                      canDoubleDown={canDoubleDown}
                      canSplit={canSplit}
                      loadingAction={loadingAction}
                      showGameStatus={showGameStatus}
                      showPayoutMessage={showPayoutMessage}
                    />
                  </Box>
                </Box>
              )}
              <Box h="70px">
                {(!gameData?.gameStarted || showBetButton) && (
                  <Betting
                    isLoading={isLoading}
                    betAmount={betAmount}
                    handleBetAmountChange={handleBetAmountChange}
                    startNewGame={startNewGame}
                    loadingAction={loadingAction}
                    dealerBankroll={dealerBankroll}
                    maxBet={gameData?.maxBet}
                    playerBalance={playerBankroll}
                    betMaxEnabled={betMaxEnabled}
                    setBetMaxEnabled={setBetMaxEnabled}
                    chainId={effectiveChainId}
                  />
                )}
              </Box>
              <Box h="65px">
                <PlayerAccount
                  currentAccount={playerAddress}
                  onAccountChange={handleAccountChange}
                  playerBalance={playerBankroll}
                  isDisabled={
                    isLoading || (gameData?.gameStarted && !showBetButton)
                  }
                />
              </Box>
            </VStack>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Blackjack;
