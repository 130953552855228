// components/Instructions.js
import React, { useState } from "react";
import {
  Box,
  Link,
  Text,
  ListItem,
  Heading,
  List,
  ListIcon,
  Icon,
  Button,
} from "@chakra-ui/react";
import { ExternalLinkIcon, TimeIcon } from "@chakra-ui/icons";
import {
  FaLayerGroup,
  FaArrowsAltH,
  FaAngleDoubleDown,
  FaUserTie,
  FaDollarSign,
  FaChartLine,
  FaEthereum,
  FaFlask,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";

const Instructions = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [howItWorksExpanded, setHowItWorksExpanded] = useState(false);

  return (
    <Box
      w="100%"
      color="whitesmoke"
      maxW="600px"
      minW="40px"
      px={3}
      py={2}
      align="center"
    >
      {/* Primary Toggle Button */}
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        borderRadius="md"
        justifyContent="center"
        alignItems="center"
        background={"rgba(14, 62, 38, .7)"}
        borderColor={"rgba(14, 62, 38, 1)"}
        w="100%"
        p={3}
        position="relative"
        textAlign="center"
        _hover={{ background: "rgba(14, 62, 38, 0.8)" }}
      >
        <Heading
          size="sm"
          color="whitesmoke"
          fontWeight="medium"
          textShadow={"0 0 50px rgba(0, 0, 0, 1)"}
        >
          What is Blockchain Blackjack?
        </Heading>
        <Icon
          as={isExpanded ? FaChevronUp : FaChevronDown}
          position="absolute"
          right="5px"
          color="whitesmoke"
          boxSize="1.25em"
        />
      </Button>

      {/* Expanded Content */}
      {isExpanded && (
        <Box
          mt={2}
          p={4}
          borderRadius="md"
          bg="rgba(0, 0, 0, 0.8)"
          textAlign="left"
          w="100%"
        >
          {/* Sub Header */}
          <Heading size="md" mb={4} textAlign="center" color="whitesmoke">
            Blackjack on the Polygon Blockchain
          </Heading>

          {/* Combined Content */}
          <List spacing={4}>
            {/* Gameplay Features */}
            <ListItem display="flex" alignItems="center">
              <ListIcon as={FaLayerGroup} color="blue.500" boxSize="20px" />
              <Text ml={3}>
                <b>Single Deck</b> One deck shuffled every hand.
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={FaArrowsAltH} color="blue.500" boxSize="20px" />
              <Text ml={3}>
                <b>Split Any Pair</b> Splitting aces deals one additional card
                to each ace.
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon
                as={FaAngleDoubleDown}
                color="blue.500"
                boxSize="20px"
              />
              <Text ml={3}>
                <b>Double Down any Two-Card Hand</b> Even after splitting.
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={FaUserTie} color="purple.500" boxSize="20px" />
              <Text ml={3}>
                <b>Soft 17</b> Dealer must hit on soft 17.
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={FaDollarSign} color="purple.500" boxSize="20px" />
              <Text ml={3}>
                <b>Blackjack Pays 3:2</b> Natural blackjack only, not after
                splitting.
              </Text>
            </ListItem>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={FaEthereum} color="purple.500" boxSize="20px" />
              <Text ml={3}>
                <b>No Bank</b> Hands are played directly against the{" "}
                <Link
                  color="blue.500"
                  href="https://soliditylang.org/"
                  isExternal
                >
                  Solidity <ExternalLinkIcon />
                </Link>{" "}
                smart contract.
              </Text>
            </ListItem>

            {/* Dynamic Limits */}
            <ListItem display="flex" alignItems="center">
              <ListIcon as={FaChartLine} color="green.500" boxSize="20px" />
              <Text ml={3}>
                <b>Dynamic Limits</b> A bet may be at most 1/16th of the
                dealer's bankroll. When a bet is placed, 16 times the bet amount
                is reserved to ensure payouts even if someone were to win 4
                double-downs in one hand.
              </Text>
            </ListItem>

            {/* Important Notes */}
            <ListItem display="flex" alignItems="center">
              <ListIcon as={TimeIcon} color="green.500" boxSize="20px" />
              <Text ml={3}>
                <b>Inactivity</b> Games unresolved after 1 hour are subject to
                automatically being settled in favor of the dealer.
              </Text>
            </ListItem>
            <ListItem display="flex">
              <ListIcon as={FaFlask} color="green.500" boxSize="20px" />
              <Text ml={3}>
                <b>Free</b> Practice using one of 20 free accounts or connect a
                wallet to play on the{" "}
                <Link
                  color="blue.500"
                  href="https://polygon.technology/blog/introducing-the-amoy-testnet-for-polygon-pos"
                  isExternal
                >
                  Polygon Amoy testnet <ExternalLinkIcon />
                </Link>
                .
              </Text>
            </ListItem>
          </List>

          {/* Nested Toggle for "How does it work?" */}
          <Button
            onClick={() => setHowItWorksExpanded(!howItWorksExpanded)}
            mt={6}
            w="100%"
            py={3}
            borderRadius="md"
            boxShadow="0 0 20px rgba(0, 0, 0, 1)"
            justifyContent="center"
            alignItems="center"
            background={"rgba(31, 60, 96, 0.7)"}
            borderColor={"rgba(31, 60, 96, 1)"}
            borderWidth={3}
            position="relative"
            textAlign="center"
            _hover={{ background: "rgba(31, 60, 96, 0.8)" }}
          >
            <Heading size="sm" color="whitesmoke">
              How Does It Work?
            </Heading>
            <Icon
              as={howItWorksExpanded ? FaChevronUp : FaChevronDown}
              position="absolute"
              right={7}
              color="whitesmoke"
              boxSize="1.25em"
            />
          </Button>

          {/* Expanded "How does it work?" Content */}
          {howItWorksExpanded && (
            <Box
              mt={4}
              p={4}
              borderRadius="md"
              bg="rgba(31, 60, 96, 0.1)"
              textAlign="left"
              w="100%"
            >
              <Text pb={2}>
                The game state is stored on the Polygon blockchain, with each
                action being a transaction triggering game progression.
              </Text>
              <Text pb={4}>
                You can play in Practice Mode or on the Polygon Amoy testnet.
              </Text>
              <Heading size="sm" pt={5} pb={2} color="whitesmoke">
                Practice Mode
              </Heading>
              <Text color="whitesmoke">
                If you haven't connected a wallet, this is what you are playing.
                The game state is stored directly on a hosted machine, which may
                be reset at any time.
              </Text>

              {/* Polygon Amoy Testnet Section */}
              <Heading
                size="sm"
                pt={5}
                pb={2}
                textAlign="left"
                color="whitesmoke"
              >
                Polygon Amoy Testnet
              </Heading>
              <Text pb={2} textAlign="left" color="whitesmoke">
                If you connect a wallet over the Polygon Amoy Testnet, you can
                play using your own funds.
              </Text>
              <Text textAlign="left" color="whitesmoke">
                <Link
                  color="blue.500"
                  href="https://polygon.technology/blog/introducing-the-amoy-testnet-for-polygon-pos"
                  isExternal
                >
                  Instructions <ExternalLinkIcon />
                </Link>{" "}
                on setting up a wallet on the Polygon Amoy Testnet.
              </Text>

              <Heading
                fontWeight="bold"
                textAlign="center"
                pt={6}
                size="lg"
                color="whitesmoke"
              >
                Good Luck!
              </Heading>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Instructions;
