// components/Card.js
import React, { useMemo, useEffect, useState, useRef } from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import CardBack from "./CardBack";

const MotionBox = motion.create(Box);

function usePrevious(value) {
  const ref = React.useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Card = ({ rank, suit, colorScheme, isSplit, shuffling = false }) => {
  const isRed = useMemo(() => suit === "♥" || suit === "♦", [suit]);
  const cardColor = useMemo(() => (isRed ? "red.500" : "black.500"), [isRed]);

  const isCardBack = rank === "?" && suit === "?";
  const flipControls = useAnimation();

  // Randomly decide if the central suit symbol is flipped
  const isSuitFlippedRef = useRef(null);
  if (isSuitFlippedRef.current === null) {
    isSuitFlippedRef.current = Math.random() > 0.5;
  }
  const isSuitFlipped = isSuitFlippedRef.current;

  // Animation variants for mounting and unmounting
  const addVariants = {
    initial: { scale: 0.75, x: 50 },
    animate: {
      scale: 1,
      x: 0,
      transition: { duration: 1, ease: "easeInOut" },
    },
    exit: {
      scale: 0.75,
      x: -50,
      transition: { duration: 1, ease: "easeInOut" },
    },
  };

  // Animation variants for flipping the card
  const flipVariants = {
    faceDown: {
      rotateY: 0,
      opacity: 1,
      scaleX: 1,
      boxShadow: shuffling ? "0px" : "0px 4px 12px rgba(0,0,0,0.24)",
      transition: {
        rotateY: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        opacity: { duration: 0.6, ease: "easeInOut" },
        boxShadow: { duration: 0.6, ease: "easeInOut" },
      },
    },
    faceUp: {
      rotateY: 180,
      opacity: 1,
      scaleX: 1,
      boxShadow: shuffling ? "0px" : "0px 4px 12px rgba(0,0,0,0.32)",
      transition: {
        rotateY: { duration: 0.6, type: "spring", stiffness: 500, damping: 30 },
        opacity: { duration: 0.6, ease: "easeInOut" },
        boxShadow: { duration: 0.6, ease: "easeInOut" },
      },
    },
  };

  const prevRank = usePrevious(rank);
  const prevSuit = usePrevious(suit);

  // State for visible rank and suit
  const [visibleRank, setVisibleRank] = useState(rank);
  const [visibleSuit, setVisibleSuit] = useState(suit);

  // State to prevent multiple flips at the same time
  const [isFlipping, setIsFlipping] = useState(false);

  useEffect(() => {
    const performFlip = async () => {
      setIsFlipping(true);
      await flipControls.start({
        scaleX: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
      });
      setVisibleRank(rank || "?");
      setVisibleSuit(suit || "?");
      await flipControls.start({
        scaleX: 1,
        transition: { duration: 0.3, ease: "easeInOut" },
      });
      setIsFlipping(false);
    };

    if (
      (prevRank === "?" || prevSuit === "?") &&
      (rank !== "?" || suit !== "?") &&
      !isFlipping
    ) {
      performFlip();
    } else {
      setVisibleRank(rank || "?");
      setVisibleSuit(suit || "?");
      flipControls.start(isCardBack ? "faceDown" : "faceUp");
    }
  }, [rank, suit, prevRank, prevSuit, flipControls, isFlipping, isCardBack]);

  // Define dynamic size and style variables based on isSplit (20% smaller when true)
  const sizing = {
    cardWidth: isSplit ? "80px" : "100px",
    cardHeight: isSplit ? "120px" : "150px",
    smallFontSize: isSplit ? "0.96rem" : "1.2rem",
    largeFontSize: isSplit ? "3.2rem" : "4rem",
    cornerOffset: isSplit ? "4px" : "5px",
    borderRadius: isSplit ? "8px" : "10px",
    boxShadow: isSplit
      ? "0px 4px 12px rgba(0,0,0,0.2)"
      : shuffling
      ? "0px 2px 4px rgba(0,0,0,0.05)"
      : "0px 5px 15px rgba(0,0,0,0.3)",
    margin: isSplit ? "0 4px" : "0 5px",
    cardBackBorderRadius: isSplit ? "8px" : "10px",
  };

  return (
    <MotionBox
      w={sizing.cardWidth}
      h={sizing.cardHeight}
      perspective="1500px"
      position="relative"
      borderRadius={sizing.borderRadius}
      boxShadow={sizing.boxShadow}
      variants={addVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      m={sizing.margin}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <MotionBox
        position="absolute"
        w="100%"
        h="100%"
        style={{ transformStyle: "preserve-3d" }}
        variants={flipVariants}
        animate={flipControls}
        borderRadius={sizing.borderRadius}
      >
        {/* Front Face */}
        <Box
          position="absolute"
          w="100%"
          h="100%"
          style={{ backfaceVisibility: "hidden" }}
          transform="rotateY(180deg)"
          bg="whitesmoke"
          borderRadius={sizing.borderRadius}
          cursor="pointer"
        >
          {!isCardBack && (
            <>
              <Box
                position="absolute"
                top={sizing.cornerOffset}
                left={sizing.cornerOffset}
                textAlign="center"
                borderRadius={sizing.borderRadius}
              >
                <Text
                  fontSize={sizing.smallFontSize}
                  color={cardColor}
                  lineHeight="1"
                >
                  {visibleRank}
                </Text>
                <Text
                  fontSize={sizing.smallFontSize}
                  color={cardColor}
                  lineHeight="1"
                >
                  {visibleSuit}
                </Text>
              </Box>

              {/* Central Suit Symbol */}
              <Text
                fontSize={sizing.largeFontSize}
                color={cardColor}
                position="absolute"
                top="50%"
                left="50%"
                transform={`translate(-50%, -50%) ${
                  isSuitFlipped ? "rotate(180deg)" : ""
                }`}
                pointerEvents="none"
              >
                {visibleSuit}
              </Text>

              {/* Bottom-Right Corner */}
              <Box
                position="absolute"
                bottom={sizing.cornerOffset}
                right={sizing.cornerOffset}
                textAlign="center"
                transform="rotate(180deg)"
                borderRadius={sizing.borderRadius}
              >
                <Text
                  fontSize={sizing.smallFontSize}
                  color={cardColor}
                  lineHeight="1"
                >
                  {visibleRank}
                </Text>
                <Text
                  fontSize={sizing.smallFontSize}
                  color={cardColor}
                  lineHeight="1"
                >
                  {visibleSuit}
                </Text>
              </Box>
            </>
          )}
        </Box>
        <Box
          position="absolute"
          w="100%"
          h="100%"
          style={{ backfaceVisibility: "hidden" }}
          borderRadius={sizing.cardBackBorderRadius}
          transform="rotateY(0deg)"
        >
          <CardBack colorScheme={colorScheme} shuffling={shuffling} />
        </Box>
      </MotionBox>
    </MotionBox>
  );
};

export default Card;
