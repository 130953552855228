// src/components/PlayerHand.js
import React, { useState, useEffect, useMemo } from "react";
import { VStack } from "@chakra-ui/react";
import Hand from "./Hand";
import GameActions from "./GameActions";
import getCorrectAction, {
  calculateHandValues,
  bestValidTotal,
  getCardValue,
} from "../utils/getCorrectAction";

const PlayerHand = ({
  cards,
  index,
  colorScheme,
  gameData,
  showLabel,
  showGameActions,
  isLoading,
  handleAction,
  canDoubleDown,
  canSplit,
  showGameStatus,
  showPayoutMessage,
}) => {
  // **1. Determine if this is the current active hand**
  const isCurrentHand = index === gameData.currentHandIndex;

  // **2. Get the dealer's upcard**
  const dealerUpcard =
    gameData.dealerHand && gameData.dealerHand.length > 0
      ? gameData.dealerHand[0]
      : null;

  // **3. Extract the real cards for this hand (excluding placeholders)**
  const realCards = useMemo(
    () => gameData?.playerHands?.[index]?.cards || [],
    [gameData.playerHands, index]
  );

  // **4. Determine if this hand is a split ace**
  const isSplitAce = useMemo(
    () => gameData?.playerHands?.[index]?.isSplitAce || false,
    [gameData.playerHands, index]
  );

  // **5. Track action history for correct recommendations**
  const [correctActionHistory, setCorrectActionHistory] = useState([]);

  // **6. Store the snapshot before the most recent action**
  const [lastSnapshot, setLastSnapshot] = useState(null);

  /**
   * Compute the correct action whenever the current hand or dealer's upcard changes,
   * provided the hand is not a split ace and the game is active.
   */
  useEffect(() => {
    const validForSnapshot =
      isCurrentHand &&
      dealerUpcard &&
      gameData.gameStarted &&
      !realCards.some((c) => c === "??") &&
      !isSplitAce;

    if (!validForSnapshot) {
      return;
    }

    // **1. Compute the recommended action based on current hand and dealer's upcard**
    const currentAction = getCorrectAction(realCards, dealerUpcard);

    // **2. Calculate supporting data**
    const playerTotals = calculateHandValues(realCards);
    const bestTotalValue = bestValidTotal(playerTotals);
    const dealerValue = getCardValue(dealerUpcard);

    // **3. Create a snapshot of the current state**
    const newSnapshot = {
      action: currentAction,
      playerHand: {
        cards: [...realCards],
        bet: gameData.betAmounts?.[index] || "0",
      },
      playerTotals,
      bestTotal: bestTotalValue,
      dealerUpcard,
      dealerValue,
    };

    // **4. Avoid duplicate snapshots**
    setCorrectActionHistory((prev) => {
      if (prev.length) {
        const last = prev[prev.length - 1];
        const actionSame = last.action === newSnapshot.action;
        const cardsSame =
          JSON.stringify(last.playerHand.cards) ===
          JSON.stringify(newSnapshot.playerHand.cards);

        if (actionSame && cardsSame) {
          return prev; // Skip adding duplicate snapshot
        }
      }
      return [...prev, newSnapshot];
    });
  }, [
    isCurrentHand,
    dealerUpcard,
    realCards,
    gameData.gameStarted,
    gameData.gameSettled,
    index,
    isSplitAce,
    gameData.betAmounts,
  ]);

  // **7. Define `currentHand` based on `currentHandIndex`**
  const currentHand = useMemo(
    () => gameData?.playerHands?.[gameData.currentHandIndex] || {},
    [gameData.playerHands, gameData.currentHandIndex]
  );

  return (
    <VStack width="100%" spacing={4} align="center">
      <Hand
        cards={cards}
        colorScheme={colorScheme}
        gameStarted={gameData.gameStarted}
        betAmount={
          index < gameData.betAmounts.length && gameData.betAmounts[index] > 0
            ? gameData.betAmounts[index]
            : null
        }
        handStatus={
          index < gameData.handStatuses.length && gameData.gameSettled
            ? gameData.handStatuses[index]
            : null
        }
        payout={
          index < gameData.payouts.length && gameData.gameSettled
            ? gameData.payouts[index]
            : null
        }
        label={
          showLabel && gameData.playerHands.length > 1
            ? `Hand ${index + 1}`
            : null
        }
        gameLoaded={gameData.gameLoaded}
        showGameStatus={showGameStatus}
        showPayoutMessage={showPayoutMessage}
        isSplit={gameData.playerHands.length > 1}
        reason={
          gameData?.handReasons?.[index] ? gameData.handReasons[index] : ""
        }
        handIndex={index}
        isSplitAce={isSplitAce}
      />

      <GameActions
        isLoading={isLoading}
        gameStarted={gameData.gameStarted}
        gameSettled={gameData.gameSettled}
        showGameActions={showGameActions && isCurrentHand}
        currentHand={isCurrentHand ? currentHand : {}}
        canDoubleDown={canDoubleDown}
        canSplit={canSplit}
        correctActionHistory={correctActionHistory}
        lastSnapshot={lastSnapshot}
        setLastSnapshot={setLastSnapshot}
        // Actions
        hit={() => handleAction("hit")(index)}
        stand={() => handleAction("stand")(index)}
        doubleDown={() => handleAction("doubleDown")(index)}
        split={() => handleAction("split")(index)}
        isSplitAce={isSplitAce}
      />
    </VStack>
  );
};

export default PlayerHand;
