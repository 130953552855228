// components/GameActions.js
import React, { useState, useEffect } from "react";
import { Button, VStack, Spinner, HStack, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import { keyframes } from "@emotion/react";

const bigPulseGreen = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 12px rgba(0, 255, 0, 0.7);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 24px rgba(0, 255, 0, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 12px rgba(0, 255, 0, 0.7);
  }
`;

const bigPulseRed = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 12px rgba(255, 0, 0, 0.7);
  }
  50% {
    transform: scale(1.08);
    box-shadow: 0 0 24px rgba(255, 0, 0, 1);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 12px rgba(255, 0, 0, 0.7);
  }
`;

const GameActions = ({
  isLoading,
  gameStarted,
  gameSettled,
  showGameActions,
  currentHand,
  canDoubleDown,
  canSplit,
  correctActionHistory,
  lastSnapshot,
  setLastSnapshot,
  hit,
  stand,
  doubleDown,
  split,
  onActionComplete,
  isSplitAce,
}) => {
  const [loadingAction, setLoadingAction] = useState("");
  const [finalState, setFinalState] = useState(false);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const [clickedAction, setClickedAction] = useState("");
  const [userChosenAction, setUserChosenAction] = useState("");

  const [persistedCanSplit, setPersistedCanSplit] = useState(false);
  const [persistedCanDoubleDown, setPersistedCanDoubleDown] = useState(false);

  useEffect(() => {
    if (!finalState && !buttonsDisabled) {
      setPersistedCanSplit(canSplit);
      setPersistedCanDoubleDown(canDoubleDown);
    }
  }, [canSplit, canDoubleDown, finalState, buttonsDisabled]);

  if (isSplitAce) {
    return (
      <VStack spacing={4}>
        <Text color="white" fontWeight="bold">
          Split Aces are automatically settled.
        </Text>
      </VStack>
    );
  }

  const actionButtons = [
    {
      label: "Hit",
      verb: "Hitting...",
      handler: hit,
      visible: true,
    },
    {
      label: "Stand",
      verb: "Standing...",
      handler: stand,
      visible: true,
    },
    {
      label: "Split",
      verb: "Splitting...",
      handler: split,
      visible: persistedCanSplit,
    },
    {
      label: "Double Down",
      verb: "Doubling Down...",
      handler: doubleDown,
      visible: persistedCanDoubleDown,
    },
  ];

  const handleActionClick = async (actionLabel, actionHandler) => {
    if (buttonsDisabled) return;

    const snapshotBeforeClick =
      correctActionHistory[correctActionHistory.length - 1] || null;
    setLastSnapshot(snapshotBeforeClick);

    setButtonsDisabled(true);
    setLoadingAction(actionLabel);
    setClickedAction(actionLabel);
    setUserChosenAction(actionLabel);

    try {
      await actionHandler();
    } catch (error) {
      console.error(`Error executing action ${actionLabel}:`, error);
    }

    await onActionComplete?.();

    setFinalState(true);

    const HIGHLIGHT_DURATION = 5000; // ms
    setTimeout(() => {
      if (gameSettled) {
        setButtonsDisabled(true);
      } else {
        setFinalState(false);
        setLoadingAction("");
        setClickedAction("");
        setUserChosenAction("");
        setButtonsDisabled(false);
      }
    }, HIGHLIGHT_DURATION);
  };

  const labelStyles = {
    Hit: {
      colorScheme: "green",
      bg: "rgba(79, 151, 81, 0.28)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(34, 120, 60, 0.6)",
      },
      boxShadow: "0px 0px 10px 4px rgba(34, 120, 60, 0.5)",
      transition: "all 0.3s ease",
    },
    Stand: {
      colorScheme: "blue",
      bg: "rgba(38, 85, 130, 0.5)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(38, 85, 130, 0.7)",
      },
      boxShadow: "0px 0px 10px 4px rgba(38, 85, 130, 0.5)",
      transition: "all 0.3s ease",
    },
    Split: {
      colorScheme: "purple",
      bg: "rgba(128, 60, 128, 0.4)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(128, 60, 128, 0.6)",
      },
      boxShadow: "0px 0px 20px 8px rgba(128, 60, 128, 0.5)",
      transition: "all 0.3s ease",
    },
    "Double Down": {
      colorScheme: "teal",
      bg: "rgba(38, 100, 100, 0.4)",
      color: "whitesmoke",
      _hover: {
        transform: "scale(1.05)",
        background: "rgba(38, 100, 100, 0.6)",
      },
      boxShadow: "0px 0px 10px 8px rgba(38, 100, 100, 0.5)",
      transition: "all 0.3s ease",
    },
  };

  const getButtonStyle = (label) => {
    const def = labelStyles[label] || {};

    const isCorrectAction = lastSnapshot && label === lastSnapshot.action;
    const isUserChoice = label === userChosenAction;

    if (finalState) {
      if (isCorrectAction) {
        if (isUserChoice) {
          return {
            ...def,
            animation: `${bigPulseGreen} 1.5s`,
          };
        }
        return {
          ...def,
          animation: `${bigPulseRed} 1.5s`,
        };
      }

      return def;
    }

    if (buttonsDisabled) {
      if (loadingAction === label) {
        return { ...def };
      }
      return { ...def, opacity: 0.6, cursor: "not-allowed" };
    }

    if (clickedAction === label) {
      return { ...def, transform: "scale(1.05)" };
    }

    return def;
  };

  const getButtonLabel = (label) => {
    if (loadingAction === label && !finalState) {
      const btn = actionButtons.find((b) => b.label === label);
      return btn?.verb || `${label}...`;
    }
    return label;
  };

  const isButtonLoading = (label) => loadingAction === label && !finalState;

  const renderButton = ({ label, handler }) => (
    <Button
      key={label}
      size="lg"
      isDisabled={buttonsDisabled || finalState || gameSettled}
      onClick={() => handleActionClick(label, handler)}
      {...getButtonStyle(label)}
      minW="100px"
      variant={finalState ? "solid" : "outline"}
      position="relative"
      overflow="hidden"
    >
      {isButtonLoading(label) && <Spinner size="md" mr={2} color="white" />}
      {getButtonLabel(label)}
    </Button>
  );

  const topRowActions = actionButtons.filter(
    (btn) => ["Hit", "Stand"].includes(btn.label) && btn.visible
  );
  const secondRowActions = actionButtons.filter(
    (btn) => ["Split", "Double Down"].includes(btn.label) && btn.visible
  );

  const renderButtonsLayout = () => {
    if (secondRowActions.length === 0) {
      return (
        <VStack spacing={4}>
          <HStack width="100%" justifyContent="center" spacing={3}>
            {topRowActions.map(renderButton)}
          </HStack>
        </VStack>
      );
    }
    return (
      <VStack spacing={4}>
        <HStack width="100%" justifyContent="center" spacing={3}>
          {topRowActions.map(renderButton)}
        </HStack>
        <HStack width="100%" justifyContent="center" px={4} spacing={3}>
          {secondRowActions.map(renderButton)}
        </HStack>
      </VStack>
    );
  };

  if (!showGameActions || !currentHand.cards?.length || !gameStarted)
    return null;

  return <VStack>{renderButtonsLayout()}</VStack>;
};

GameActions.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  gameStarted: PropTypes.bool.isRequired,
  gameSettled: PropTypes.bool.isRequired,
  showGameActions: PropTypes.bool.isRequired,
  currentHand: PropTypes.shape({
    cards: PropTypes.arrayOf(PropTypes.string).isRequired,
    isSplitAce: PropTypes.bool,
  }).isRequired,
  canDoubleDown: PropTypes.bool.isRequired,
  canSplit: PropTypes.bool.isRequired,
  correctActionHistory: PropTypes.array.isRequired,
  lastSnapshot: PropTypes.object,
  setLastSnapshot: PropTypes.func.isRequired,
  hit: PropTypes.func.isRequired,
  stand: PropTypes.func.isRequired,
  doubleDown: PropTypes.func.isRequired,
  split: PropTypes.func.isRequired,
  onActionComplete: PropTypes.func,
  isSplitAce: PropTypes.bool,
};

export default GameActions;
