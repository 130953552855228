// hooks/useFetchGameData.js
import { useState, useCallback, useEffect, useRef } from "react";
import { ethers } from "ethers";
import BlackjackABI from "../BlackjackABI.json";

const useFetchGameData = (address, provider, addAlert, blackjackAddress) => {
  const [gameData, setGameData] = useState({
    playerHands: [],
    dealerHand: [],
    betAmounts: [],
    handStatuses: [],
    handReasons: [],
    payouts: [],
    gameStarted: false,
    gameSettled: false,
    currentHandIndex: 0,
    maxBet: "0",
    totalEscrow: "0",
    dealerBankroll: "0.000",
    playerBankroll: "0.000",
  });

  const [isLoading, setIsLoading] = useState(false);

  const formatCard = (card) => {
    const rankMapping = [
      "A",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "J",
      "Q",
      "K",
    ];
    const suitMapping = ["♠", "♥", "♦", "♣"];

    const rankNumber = Number(card.rank);
    const suitNumber = Number(card.suit);

    if (rankNumber === 0) {
      return "??";
    }

    const rank = rankMapping[rankNumber - 1] || "??";
    const suit = suitMapping[suitNumber] || "??";

    return `${rank}${suit}`;
  };

  const isUpdatingRef = useRef(false);

  const fetchGameData = useCallback(async () => {
    if (isUpdatingRef.current || !address || !provider || !blackjackAddress)
      return;
    isUpdatingRef.current = true;
    setIsLoading(true);

    try {
      const contract = new ethers.Contract(
        blackjackAddress,
        BlackjackABI,
        provider
      );

      //console.log("Calling gameStatus on contract...");
      const { game, totalEscrowValue } = await contract.gameStatus(address);
      //console.log("Received game status:", game);
      //console.log("Total Escrow Value (Wei):", totalEscrowValue.toString());

      // **Incorporate isSplitAce in playerHands**
      const playerHands = game.playerHands?.length
        ? game.playerHands.map((handStruct) => ({
            cards: handStruct.cards.map(formatCard),
            isSplitAce: handStruct.isSplitAce, // Ensure the smart contract provides this flag
          }))
        : [{ cards: [], isSplitAce: false }];

      let dealerHand = [];
      if (game.dealerHand.cards?.length) {
        dealerHand = game.dealerHand.cards.map(formatCard);
      }

      const betAmounts =
        game.playerHands?.map((hand) => ethers.formatEther(hand.bet)) || [];
      const payouts =
        game.playerHands?.map((hand) => ethers.formatEther(hand.payout)) || [];

      const resultMapping = [
        "NotSet",
        "BLACKJACK!",
        "Push",
        "Player Won",
        "Dealer Won",
        "Busted",
      ];
      const handStatuses =
        game.playerHands?.map(
          (hand) => resultMapping[hand.result] || "Unknown"
        ) || [];
      const handReasons =
        game.playerHands?.map((hand) => (hand.reason ? hand.reason : "")) || [];

      const totalEscrow = ethers.formatEther(totalEscrowValue);
      const contractBalanceWei = await provider.getBalance(blackjackAddress);
      const playerBalanceWei = await provider.getBalance(address);

      const contractBalanceEther = ethers.formatEther(contractBalanceWei);
      const playerBalanceEther = ethers.formatEther(playerBalanceWei);

      //console.log("Contract Balance (Ether):", contractBalanceEther);
      //console.log("Player Balance (Ether):", playerBalanceEther);

      const contractBalanceNumber = parseFloat(contractBalanceEther);
      const totalEscrowNumber = parseFloat(totalEscrow);
      const playerBalanceNumber = parseFloat(playerBalanceEther);

      let maxBetNumber = 0;

      if (contractBalanceNumber >= totalEscrowNumber) {
        maxBetNumber = (contractBalanceNumber - totalEscrowNumber) / 10;
      }

      if (playerBalanceNumber < maxBetNumber) {
        maxBetNumber = playerBalanceNumber;
      }

      maxBetNumber = Math.max(maxBetNumber, 0);
      const maxBetEther = maxBetNumber.toFixed(3);
      const totalEscrowEther = totalEscrowNumber.toFixed(3);

      //console.log(`Calculated maxBet (Ether): ${maxBetEther}`);
      //console.log(`Total Escrow (Ether): ${totalEscrowEther}`);

      const newGameData = {
        playerHands,
        dealerHand,
        betAmounts,
        payouts,
        handStatuses,
        handReasons,
        gameStarted: playerHands.some((hand) =>
          hand.cards.some((card) => card !== "??")
        ),
        gameSettled: game.settled,
        currentHandIndex: Number(game.currentHandIndex),
        maxBet: maxBetEther,
        totalEscrow: totalEscrowEther,
        dealerBankroll: parseFloat(contractBalanceEther).toFixed(3),
        playerBankroll: parseFloat(playerBalanceEther).toFixed(3),
      };

      //console.log("Fetched Game Data:", newGameData);

      setGameData(newGameData);
    } catch (err) {
      addAlert({
        status: "error",
        title: "Error Fetching Game Data",
        description: `Error fetching game data: ${err.message}`,
      });
      console.error("Error fetching game data:", err);
      setGameData((prev) => ({ ...prev, gameStarted: false }));
    } finally {
      isUpdatingRef.current = false;
      setIsLoading(false);
    }
  }, [address, provider, addAlert, blackjackAddress]);

  useEffect(() => {
    fetchGameData();
  }, [fetchGameData]);

  return { gameData, setGameData, fetchGameData, isLoading, setIsLoading };
};

export default useFetchGameData;
