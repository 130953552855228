// BackgroundGrid.js
import React from "react";
import { Box } from "@chakra-ui/react";

const BackgroundGrid = ({ windowDimensions, colors, suitColors }) => {
  // Adding extra columns and rows to ensure the grid extends beyond the visible area
  const columns = Math.ceil(windowDimensions.width / 80) + 3; // +3 to extend beyond the frame
  const rows = Math.ceil(windowDimensions.height / 80) + 3; // +3 to extend vertically

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={-1}
      overflow="hidden"
    >
      {/* Primary grid */}
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${columns}, 80px)`}
        gridTemplateRows={`repeat(${rows}, 80px)`}
        position="absolute"
        top="-120px" // Shift to start before the visible frame
        left="-120px" // Shift to start before the visible frame
      >
        {Array.from({ length: columns * rows }).map((_, index) => {
          const row = Math.floor(index / columns);
          const col = index % columns;

          const colorIndex = ((row % 4) + (col % 4)) % 4;

          const backgroundColor = colors[colorIndex];
          const suitColor = suitColors[colorIndex];

          return (
            <Box
              key={`primary-${index}`}
              width="80px"
              height="80px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              background={backgroundColor}
              style={{
                clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
              }}
            >
              <Box
                as="span"
                fontSize="50px"
                fontWeight="bold"
                color={suitColor}
              >
                {["♠", "♥", "♦", "♣"][index % 4]}
              </Box>
            </Box>
          );
        })}
      </Box>

      {/* Secondary grid */}
      <Box
        display="grid"
        gridTemplateColumns={`repeat(${columns}, 80px)`}
        gridTemplateRows={`repeat(${rows}, 80px)`}
        position="absolute"
        top="-80px" // Shift slightly for offset effect
        left="-80px" // Shift slightly for offset effect
      >
        {Array.from({ length: columns * rows }).map((_, index) => {
          const row = Math.floor(index / columns);
          const col = index % columns;

          // Adjusted logic for the secondary grid color offset
          const colorIndex = ((row % 4) + (col % 4) + 3) % 4;

          const backgroundColor = colors[colorIndex];
          const suitColor = suitColors[colorIndex];

          return (
            <Box
              key={`secondary-${index}`}
              width="80px"
              height="80px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              background={backgroundColor}
              style={{
                clipPath: "polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)",
              }}
            >
              <Box
                as="span"
                fontSize="50px"
                fontWeight="bold"
                color={suitColor}
              >
                {["♠", "♥", "♦", "♣"][colorIndex]}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default BackgroundGrid;
