// utils/getCorrectAction.js

/**
 * Returns the numeric value of a single card string, e.g. "A♠" => 11, "K♦" => 10, "9♣" => 9
 * @param {string} cardStr
 * @returns {number}
 */
function getCardValue(cardStr) {
  if (!cardStr || cardStr === "??") return 0;
  const rank = cardStr.slice(0, -1); // e.g. "10♣" -> "10", "K♠" -> "K"
  if (rank === "A") return 11; // Ace = 11
  if (["K", "Q", "J"].includes(rank)) return 10;

  const numeric = parseInt(rank, 10);
  return Number.isNaN(numeric) ? 0 : numeric;
}

/**
 * Calculates all possible totals for a given hand (due to Aces).
 * @param {string[]} hand - e.g. ['A♠', '6♦', 'K♣']
 * @returns {number[]} array of possible totals (some may be >21)
 */
function calculateHandValues(hand) {
  if (!Array.isArray(hand) || hand.length === 0) return [0];

  let baseValue = 0;
  let aceCount = 0;

  for (const card of hand) {
    const val = getCardValue(card);
    if (val === 11) {
      aceCount++;
    }
    baseValue += val;
  }

  // For each possible number of Aces counted as 11 (vs. 1),
  // generate a distinct total.
  const totals = [];
  for (let i = 0; i <= aceCount; i++) {
    // i = # of Aces as 11; the rest (aceCount - i) = 1
    // each Ace reduced from 11 to 1 => subtract 10
    const total = baseValue - 10 * (aceCount - i);
    totals.push(total);
  }

  // Sort them ascending, remove duplicates
  return [...new Set(totals)].sort((a, b) => a - b);
}

/**
 * Pick the highest total <= 21 if possible; else the smallest total (bust).
 * @param {number[]} totals
 * @returns {number}
 */
function bestValidTotal(totals) {
  const valid = totals.filter((t) => t <= 21);
  if (valid.length) return Math.max(...valid);
  return Math.min(...totals); // all bust => pick smallest
}

/**
 * Determine if the hand is "soft" (at least one Ace counted as 11).
 * @param {string[]} hand
 * @returns {boolean}
 */
function isSoftHand(hand) {
  const totals = calculateHandValues(hand);
  const bestTotal = bestValidTotal(totals);
  if (bestTotal > 21) return false; // busted => not soft

  // If there's an Ace, and best total can be reduced by 10, it's soft
  for (const t of totals) {
    if (t === bestTotal && t !== bestTotal - 10) {
      return hand.some((card) => card.startsWith("A"));
    }
  }
  return false;
}

/**
 * Check if exactly two cards share the same rank (for a Split).
 * @param {string[]} hand - e.g. ["8♦", "8♣"]
 * @returns {boolean}
 */
function isPair(hand) {
  if (hand.length !== 2) return false;
  const rank1 = hand[0].slice(0, -1);
  const rank2 = hand[1].slice(0, -1);
  return rank1 === rank2;
}

/**
 * Decide "Split", "Stand", "Hit", or "Double Down" for a pair.
 * @param {string} pairCard e.g. "8♦"
 * @param {number} dealerValue e.g. 10
 * @returns {"Split"|"Stand"|"Hit"|"Double Down"|null}
 */
function getPairAction(pairCard, dealerValue) {
  if (!pairCard) return null;
  const value = getCardValue(pairCard); // 11 if Ace, 10 if K/Q/J/T, else numeric

  switch (value) {
    case 11: // A-A
      return "Split";
    case 10: // 10-10, J-J, Q-Q, K-K
      return "Stand"; // standard: never split 10s
    case 9:
      // Split 9 vs 2-6, 8-9 => stand vs 7,10,A
      if (dealerValue === 7 || dealerValue === 10 || dealerValue === 11) {
        return "Stand";
      }
      return "Split";
    case 8:
      return "Split"; // always split 8s
    case 7:
      if (dealerValue >= 2 && dealerValue <= 7) return "Split";
      return "Hit";
    case 6:
      if (dealerValue >= 2 && dealerValue <= 6) return "Split";
      return "Hit";
    case 5:
      // 5-5 => treat as 10 => "Double Down" if dealer 2-9, else "Hit"
      return null;
    case 4:
      if (dealerValue === 5 || dealerValue === 6) return "Split";
      return "Hit";
    case 3:
    case 2:
      if (dealerValue >= 2 && dealerValue <= 7) return "Split";
      return "Hit";
    default:
      return null;
  }
}

/**
 * Decide the action for a "soft" total (Ace = 11).
 * - Soft 13/14 => Double vs 5-6, else Hit
 * - Soft 15/16 => Double vs 4-6, else Hit
 * - Soft 17 => Double vs 3-6, else Hit
 * - Soft 18 => Stand vs 2,7,8; Hit vs 9,10,A; else Double
 * - Soft 19/20 => Stand
 */
function getSoftAction(total, dealerValue) {
  switch (total) {
    case 20:
    case 19:
      return "Stand";
    case 18:
      if ([2, 7, 8].includes(dealerValue)) return "Stand";
      if (dealerValue >= 9 || dealerValue === 11) return "Hit";
      return "Double Down";
    case 17:
      if (dealerValue >= 3 && dealerValue <= 6) return "Double Down";
      return "Hit";
    case 16:
    case 15:
      if (dealerValue >= 4 && dealerValue <= 6) return "Double Down";
      return "Hit";
    case 14:
    case 13:
      if (dealerValue >= 5 && dealerValue <= 6) return "Double Down";
      return "Hit";
    default:
      return "Hit"; // fallback
  }
}

/**
 * Decide the action for a "hard" total (no Ace or Ace counted as 1).
 * - Hard 9 => Double vs 3-6, else Hit
 * - Hard 10 => Double vs 2-9, else Hit
 * - Hard 11 => Always Double
 * - Hard 12 => Stand vs 4-6, else Hit
 * - Hard 13-16 => Stand vs 2-6, else Hit
 * - Hard >=17 => Stand
 */
function getHardAction(total, dealerValue) {
  if (total >= 17) {
    return "Stand";
  } else if (total >= 13 && total <= 16) {
    if (dealerValue >= 2 && dealerValue <= 6) return "Stand";
    return "Hit";
  } else if (total === 12) {
    if (dealerValue >= 4 && dealerValue <= 6) return "Stand";
    return "Hit";
  } else if (total === 11) {
    return "Double Down";
  } else if (total === 10) {
    if (dealerValue >= 2 && dealerValue <= 9) return "Double Down";
    return "Hit";
  } else if (total === 9) {
    if (dealerValue >= 3 && dealerValue <= 6) return "Double Down";
    return "Hit";
  } else {
    // 8 or less => Hit
    return "Hit";
  }
}

/**
 * Main function: Given the player's hand and dealer's upcard, decide correct action.
 * @param {string[]} playerHand - e.g. ["A♠", "7♦"]
 * @param {string} dealerUpcard - e.g. "K♣"
 * @returns {"Hit"|"Stand"|"Double Down"|"Split"}
 */
function getCorrectAction(playerHand, dealerUpcard) {
  if (!dealerUpcard || !playerHand || playerHand.length < 1) {
    console.error("[getCorrectAction] Invalid input => fallback to Stand.");
    return "Stand";
  }

  const dealerValue = getCardValue(dealerUpcard) || 10;
  const totals = calculateHandValues(playerHand);
  const total = bestValidTotal(totals);
  const soft = isSoftHand(playerHand);

  let action = null;

  // If 2-card pair, check pair strategy first
  if (playerHand.length === 2 && isPair(playerHand)) {
    const pairAction = getPairAction(playerHand[0], dealerValue);
    if (pairAction) {
      action = pairAction;
    }
  }

  // If no action from pair logic, decide based on soft or hard hand
  if (!action) {
    if (soft) {
      action = getSoftAction(total, dealerValue);
    } else {
      action = getHardAction(total, dealerValue);
    }
  }

  // Debug: log intermediate info (comment out if too noisy):
  // console.log(`[getCorrectAction] DealerUpcard: ${dealerUpcard}, PlayerHand: ${playerHand}`);
  // console.log(`  Totals: ${totals.join(", ")} => best ${total}, isSoft: ${soft}, chosenAction: ${action}`);

  return action;
}

// Named exports for your convenience
export {
  getCardValue,
  calculateHandValues,
  bestValidTotal,
  isSoftHand,
  isPair,
};

// Default export is the main function
export default getCorrectAction;
