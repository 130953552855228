// utils/ethersUtils.js
import { ethers } from "ethers";
import BlackjackABI from "../BlackjackABI.json";

/**
 * Creates and returns a contract instance.
 *
 * @param {Signer} signer - The signer to connect with the contract.
 * @param {string} blackjackAddress - The address of the Blackjack contract.
 * @returns {Contract} - The contract instance.
 */
export const getContract = (signer, blackjackAddress) => {
  if (!signer) {
    throw new Error(
      "Signer is not defined. Ensure you are passing a valid signer."
    );
  }
  return new ethers.Contract(blackjackAddress, BlackjackABI, signer);
};

/**
 * Executes a contract function using the provided signer.
 *
 * @param {Object} params - The parameters for the function.
 * @param {Signer} params.signer - The ethers signer.
 * @param {string} params.blackjackAddress - The address of the Blackjack contract.
 * @param {Function} params.setIsLoading - Function to set loading state.
 * @param {Function} params.fetchGameData - Function to fetch game data.
 * @param {Function} params.addAlert - Function to add alerts.
 * @param {Function} params.updateBalances - Function to update balances.
 * @param {string} params.functionName - The contract function name to execute.
 * @param {Array} params.functionArgs - Arguments for the contract function.
 * @param {string|null} params.value - The value to send with the transaction.
 * @param {Function} params.setLoadingAction - Function to set loading action.
 * @param {number} [params.maxRetries=5] - Maximum number of retry attempts.
 * @returns {Promise<void>}
 */
export const executeContractFunction = async ({
  signer,
  blackjackAddress,
  setIsLoading,
  fetchGameData,
  addAlert,
  updateBalances = () => {}, // Default to noop
  functionName,
  functionArgs = [],
  value = null,
  setLoadingAction,
  maxRetries = 5,
}) => {
  setIsLoading(true);
  let attempts = 0;
  let success = false;
  let lastError = null;

  if (!signer) {
    console.error("Signer is not defined.");
    addAlert({
      status: "error",
      title: "Signer Not Available",
      description: "Signer is not available for transaction execution.",
    });
    setIsLoading(false);
    return;
  }

  // Fetch network information
  let network;
  let providerUrl = "Provider URL not available";
  try {
    network = await signer.provider.getNetwork();
    // Attempt to fetch the provider URL if it's a JsonRpcProvider
    if (signer.provider.connection && signer.provider.connection.url) {
      providerUrl = signer.provider.connection.url;
    }
    //console.log( `Executing transaction on network: ${network.name} (chainId: ${network.chainId})`);
    //console.log(`Provider URL: ${providerUrl}`);
  } catch (error) {
    console.error("Error fetching network information:", error);
    addAlert({
      status: "error",
      title: "Network Information Error",
      description: "Unable to fetch network information from the signer.",
    });
    setIsLoading(false);
    return;
  }

  const contract = getContract(signer, blackjackAddress);
  //console.log(`Contract initialized at address: ${blackjackAddress}`);

  while (attempts < maxRetries && !success) {
    try {
      attempts += 1;

      const tx = value
        ? await contract[functionName](...functionArgs, { value })
        : await contract[functionName](...functionArgs);

      //console.log(`Transaction sent: ${tx.hash}`);
      //console.log(`Waiting for transaction confirmation...`);
      await tx.wait();

      //console.log(`Transaction confirmed: ${tx.hash}`);

      await fetchGameData();
      await updateBalances(); // Safe to call even if it's noop
      success = true;
    } catch (err) {
      lastError = err;
      console.error(`Attempt ${attempts} failed:`, err);

      if (err.code === "INSUFFICIENT_FUNDS" || err.code === "CALL_EXCEPTION") {
        console.error(`Error code: ${err.code}. No further retries.`);
        break; // Exit retry loop if funds are insufficient or call fails
      }

      const delay = Math.pow(2, attempts) * 1000;
      //console.log(`Retrying in ${delay / 1000} seconds...`);
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  }

  if (!success) {
    const reasonMatch = lastError?.message.match(/reason="([^"]+)"/);
    const reason = reasonMatch ? reasonMatch[1] : "An unknown error occurred";

    addAlert({
      status: "error",
      title: `Error during ${functionName}`,
      description: `Reason: ${reason}`,
    });

    console.error(`All ${attempts} attempts failed.`);
    console.error(lastError);
  }

  setLoadingAction("");
  setIsLoading(false);
};
