// Shuffling.js
import React, { useMemo } from "react";
import { Box } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import Card from "./Card";

function looseInterleave(leftPile, rightPile) {
  const combined = [];
  let l = 0;
  let r = 0;

  while (l < leftPile.length || r < rightPile.length) {
    // Randomly take 1–3 cards from the left
    const leftTake = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < leftTake && l < leftPile.length; i++) {
      combined.push(leftPile[l]);
      l++;
    }

    // Randomly take 1–3 cards from the right
    const rightTake = Math.floor(Math.random() * 3) + 1;
    for (let i = 0; i < rightTake && r < rightPile.length; i++) {
      combined.push(rightPile[r]);
      r++;
    }
  }

  return combined;
}

const Shuffling = ({ colorScheme }) => {
  const deckSize = 52;

  // UseMemo so this only runs once per mount
  const { combinedDeck, randomParams, zIndices } = useMemo(() => {
    // 1) Random cut of the deck
    const cutPoint = Math.floor(Math.random() * deckSize);
    const leftDeck = Array.from({ length: cutPoint }, (_, i) => ({
      side: "left",
      index: i,
    }));
    const rightDeck = Array.from({ length: deckSize - cutPoint }, (_, i) => ({
      side: "right",
      index: i,
    }));

    // 2) Loosely interleave
    const combined = looseInterleave(leftDeck, rightDeck);

    // 3) Random angles/offsets.
    //    Notice we increased startY to ±150 instead of ±40, so they can come from well above or below.
    const params = combined.map(() => ({
      startX: (Math.random() - 0.5) * 600, // -300 to 300 horizontally
      startY: (Math.random() - 0.5) * 300, // -150 to 150 vertically
      startRot: (Math.random() - 0.5) * 40, // -20 to 20 deg rotation
    }));

    // 4) Randomize z-indices
    const zArr = Array.from({ length: combined.length }, (_, i) => i + 1);
    for (let i = zArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [zArr[i], zArr[j]] = [zArr[j], zArr[i]];
    }

    return {
      combinedDeck: combined,
      randomParams: params,
      zIndices: zArr,
    };
  }, [deckSize]);

  // Creates unique keyframes for each card
  const createShuffleAnimation = (startX, startY, startRot) => keyframes`
    0% {
      opacity: 0;
      transform: translate(${startX}px, ${startY}px) rotate(${startRot}deg);
    }
    15% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(0deg);
    }
  `;

  // Fade out keyframe
  const fadeOut = keyframes`
    from { opacity: 1; }
    to { opacity: 0; }
  `;

  // Animation settings
  const shuffleDuration = 1.5; // inbound movement time
  const fadeOutDuration = 1; // fade-out time
  const cardDelayIncrement = 0.3; // sequential offset between cards

  return (
    <Box position="relative" height="100%" width="100%" overflow="hidden">
      {combinedDeck.map((cardData, idx) => {
        const { startX, startY, startRot } = randomParams[idx];
        const shuffleAnimation = createShuffleAnimation(
          startX,
          startY,
          startRot
        );

        // Each card starts a little later than the previous one
        const startDelay = idx * cardDelayIncrement;
        const fadeOutDelay = startDelay + shuffleDuration; // fade out after movement finishes

        return (
          <Box
            key={`${cardData.side}-${cardData.index}`}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            animation={`
              ${shuffleAnimation} ${shuffleDuration}s ease-out ${startDelay}s forwards,
              ${fadeOut} ${fadeOutDuration}s ease-out ${fadeOutDelay}s forwards
            `}
            zIndex={zIndices[idx]}
            pointerEvents="none"
          >
            <Card
              rank="?"
              suit="?"
              colorScheme={colorScheme}
              shuffling={true}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default Shuffling;
