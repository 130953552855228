// components/Betting.js
import React, { useCallback, useState } from "react";
import { HStack, Input, Button, Tooltip, Switch, Text } from "@chakra-ui/react";

const Betting = ({
  isLoading,
  betAmount,
  handleBetAmountChange,
  startNewGame,
  maxBet,
  betMaxEnabled,
  setBetMaxEnabled,
}) => {
  const [hasInteracted, setHasInteracted] = useState(false);

  // Parse betAmount to float for validation
  const numericBet = parseFloat(betAmount);
  const isBetAmountValid =
    !isNaN(numericBet) && numericBet >= 0.001 && numericBet <= maxBet;

  // Tooltip message for invalid input
  const tooltipMessage =
    !isBetAmountValid && betAmount !== ""
      ? `Bet must be between 0.001 and ${parseFloat(maxBet)}`
      : "";

  // Handle input change and track interaction
  const handleInputChange = (value) => {
    if (!hasInteracted) setHasInteracted(true);
    handleBetAmountChange(value);
  };

  // Handle click on the Bet Max box
  const toggleBetMax = useCallback(() => {
    if (!isLoading) {
      const newBetMaxEnabled = !betMaxEnabled;
      setBetMaxEnabled(newBetMaxEnabled);
      if (newBetMaxEnabled) {
        handleBetAmountChange(maxBet);
      } else {
        handleBetAmountChange("");
      }
    }
  }, [
    isLoading,
    betMaxEnabled,
    setBetMaxEnabled,
    handleBetAmountChange,
    maxBet,
  ]);

  return (
    !isLoading && (
      <HStack
        align="center"
        spacing={3}
        p={4}
        bg="#2f5a46"
        borderRadius="10px"
        border="1px solid whitesmoke"
        boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
      >
        {/* Bet Amount Input with currency type label on the left */}
        <Tooltip
          label={tooltipMessage}
          isOpen={hasInteracted && !isBetAmountValid && betAmount !== ""}
          hasArrow
          bg="red.600"
          color="whitesmoke"
          placement="top"
        >
          <Input
            name="betAmount"
            type="text"
            value={betAmount}
            variant="outline"
            onChange={(e) => handleInputChange(e.target.value)}
            bg="#35654d"
            color="whitesmoke"
            _hover={{ borderColor: "whitesmoke" }}
            _focus={{ borderColor: "whitesmoke" }}
            w="90px"
          />
        </Tooltip>

        {/* Bet Button */}
        <Button
          onClick={startNewGame}
          size="md"
          width="60px"
          bg="#35654d"
          color="whitesmoke"
          border="1px solid whitesmoke"
          _hover={{ backgroundColor: "#2f5a46", transform: "scale(1.02)" }}
          _active={{ backgroundColor: "#254c3a" }}
          isDisabled={!isBetAmountValid || isLoading}
        >
          Bet
        </Button>

        {/* Bet Max Toggle with Clickable Box */}
        <HStack
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          bg="#35654d"
          border="1px solid whitesmoke"
          borderRadius="10px"
          boxShadow="0px 4px 8px rgba(0, 0, 0, 0.25)"
          cursor="pointer"
          _hover={{ backgroundColor: "#2f5a46", transform: "scale(1.02)" }}
          onClick={toggleBetMax}
          h="40px"
          pl="10px"
          pr="10px"
        >
          <Switch
            isChecked={betMaxEnabled}
            onChange={() => {}}
            colorScheme="green"
            size="sm"
            isDisabled={isLoading}
            pointerEvents="none"
            name="betMax"
          />
          <Text fontSize="md" color="whitesmoke" wordBreak="keep-all">
            Bet&nbsp;Max
          </Text>
        </HStack>
      </HStack>
    )
  );
};

export default React.memo(Betting);
