import React from "react";
import { Heading, Box, Flex, Spacer } from "@chakra-ui/react";

const Header = ({ isStandalone }) => {
  return (
    <Box
      pt={isStandalone ? 10 : 0}
      backgroundColor="#0E3E26"
      opacity={0.9}
      color="whitesmoke"
      align="center"
    >
      <Flex align="center" justify="space-between" maxW="600px" p={3}>
        <Heading
          px={4}
          color="#5CAF5D"
          textShadow="0px 0px 20px rgba(77, 139, 76, 1)"
        >
          ♠
        </Heading>
        <Heading
          px={2}
          color="#5CAF5D"
          textShadow="0px 0px 20px rgba(77, 139, 76, 1)"
        >
          ♥
        </Heading>
        <Spacer />
        <Heading>Blackjack</Heading>
        <Spacer />
        <Heading
          px={2}
          color="#5CAF5D"
          textShadow="0px 0px 20px rgba(77, 139, 76, 1)"
        >
          ♦
        </Heading>
        <Heading
          px={4}
          color="#5CAF5D"
          textShadow="0px 0px 20px rgba(77, 139, 76, 1)"
        >
          ♣
        </Heading>
      </Flex>
    </Box>
  );
};

export default Header;
