// hooks/useCardAnimation.js
import { useCallback, useRef, useEffect } from "react";

const useCardAnimation = (
  gameData,
  cardShown,
  setAnimatedPlayerHands,
  setAnimatedDealerHand,
  setCardShown,
  setLastFlippedCardIndex
) => {
  const gameDataRef = useRef(gameData);

  useEffect(() => {
    gameDataRef.current = gameData;
  }, [gameData]);

  const playerAnimationQueue = useRef([]);
  const dealerAnimationQueue = useRef([]);
  const playerAnimationInProgress = useRef(false);
  const dealerAnimationInProgress = useRef(false);

  const processQueue = useCallback(async (queue, inProgress) => {
    if (inProgress.current || queue.current.length === 0) {
      return;
    }

    inProgress.current = true;
    while (queue.current.length > 0) {
      const nextAnimation = queue.current.shift();
      await nextAnimation();
    }
    inProgress.current = false;
  }, []);

  const queuePlayerAnimation = useCallback(
    (animation) => {
      playerAnimationQueue.current.push(animation);
      processQueue(playerAnimationQueue, playerAnimationInProgress);
    },
    [processQueue]
  );

  const queueDealerAnimation = useCallback(
    (animation) => {
      dealerAnimationQueue.current.push(animation);
      processQueue(dealerAnimationQueue, dealerAnimationInProgress);
    },
    [processQueue]
  );

  const flipHandsSequentially = useCallback(
    async (handType) => {
      const currentGameData = gameDataRef.current;
      if (handType === "player") {
        queuePlayerAnimation(async () => {
          for (let i = 0; i < currentGameData.playerHands.length; i++) {
            for (let j = 0; j < currentGameData.playerHands[i].length; j++) {
              //console.log(`Flipping player ${i} card ${j}`);
              await new Promise((resolve) => setTimeout(resolve, 300));

              setCardShown((prev) => {
                const updated = { ...prev };
                // Ensure the player array and specific hand exist
                if (!updated.player[i]) {
                  updated.player[i] = [];
                }
                updated.player[i][j] = true;
                return updated;
              });

              setAnimatedPlayerHands((prev) => {
                const updated = [...prev];
                if (!updated[i]) {
                  updated[i] = [];
                }
                updated[i][j] = currentGameData.playerHands[i][j];
                return updated;
              });
            }
          }
        });
      } else if (handType === "dealer") {
        queueDealerAnimation(async () => {
          for (let j = 0; j < currentGameData.dealerHand.length; j++) {
            //console.log(`Flipping dealer card ${j}`);
            await new Promise((resolve) => setTimeout(resolve, 300));

            setCardShown((prev) => {
              const updated = { ...prev };
              // Initialize the dealer array if it doesn't exist
              if (!Array.isArray(updated.dealer)) {
                updated.dealer = [];
              }
              // Ensure the dealer array has enough elements
              while (updated.dealer.length <= j) {
                updated.dealer.push(false);
              }
              updated.dealer[j] = true;
              return updated;
            });

            setAnimatedDealerHand((prev) => {
              const updated = [...prev];
              updated[j] = currentGameData.dealerHand[j];
              return updated;
            });
          }
        });
      }
    },
    [
      setAnimatedPlayerHands,
      setAnimatedDealerHand,
      setCardShown,
      queuePlayerAnimation,
      queueDealerAnimation,
    ]
  );

  return { flipHandsSequentially };
};

export default useCardAnimation;
